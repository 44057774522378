import React, { useState, useEffect } from "react";
import Home from "./home";
import {
  GET_PRODUCT_IDS_CONDITION,
  CHECK_PRODUCT_ID_CONDITION,
  GET_ALL_IMAGES,
} from "../api/home";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import {GET_IMAGE_URL, TABLE} from "../main";
import moment from "moment";
import * as tf from "@tensorflow/tfjs";

export const UPDATE_TIME = gql`
  mutation MyMutation(
    $id: Int!,
    $time: String!
  ) {
    update_torso_arm_correction(
      where: { id: { _eq: $id } }
      _set: {
        last_visited: $time
        }
    ) {
      affected_rows
    }
  }
`;



function App(props) {

  const history = useHistory();

  useEffect(() => {
    // tf.setBackend('cpu')
    // tf.enableDebugMode()
  }, [])

  const { data: data, loading: loading } = useQuery(GET_IMAGE_URL, {
    variables: {
      id: parseInt(props.match.params.imageid)
    }
  });
  // const [update_data, { error: formSubmitError, loading: formLoading }] = useMutation(UPDATE_SEGMENTATION, {
  //   onError(error) {
  //     alert(`The form did not submit. Please check your internet connection. \n For Technical Purpose: ${error}`)
  //   },
  //   onCompleted(data) {
  //     if (data) {
  //       console.log("updated...")
  //       let nextid = parseInt(props.match.params.imageid) + 1;
  //       history.push(`/imageid=${nextid}`)
  //       window.location.reload()
  //     }
  //   }
  // });

  const [update_time] = useMutation(UPDATE_TIME)

  const linkChange = (link) => {
    if (link === undefined) {
      return undefined;
    } else {
      return `https://storage.googleapis.com/download/storage/v1/b/${
        link.split("/")[3]
        }/o/${link
          .substring(34 + link.split("/")[3].length)
          .replace(/[/]/g, "%2F")}?alt=media`;
    }
  };
  const handleNext = async () => {
    await update_time({
      variables: {
        id: parseInt(props.match.params.imageid),
        time: moment().format('LLL')
      }
    })
    tf.disposeVariables()
    let nextid = parseInt(props.match.params.imageid) + 1;
    history.push(`/name=${props.match.params.name}/imageid=${nextid}`)
  };

  if (loading) {
    return <div>loading</div>;
  }

  return (
    <div>
      <Home person_data={data && data[TABLE][0]} nextImage={handleNext} props={props} />
    </div>
  ); 

  // const returnUI = () => {
  //   return (
  //     <div>
  //       <Home person_data={data && data[TABLE][0]} nextImage={handleNext} props={props} />
  //     </div>
  //   ); 
  // }

  // return tf.setBackend('wasm').then(() => returnUI());

}

export default App;
