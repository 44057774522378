import * as tf from "@tensorflow/tfjs";
import mergeImages from "merge-images";
import {TABLE} from "../main";
export const linkChange_id = (id, type) => {
  let link = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/torso_arm_pred/${type}/${id}.png`;
  if (id === undefined) {
    return undefined;
  } else {
    return `https://storage.googleapis.com/download/storage/v1/b/${
      link.split("/")[3]
    }/o/${link
      .substring(34 + link.split("/")[3].length)
      .replace(/[/]/g, "%2F")}?alt=media`;
  }
};

export const predictionOnload = (pred, type, image, setLoaded, savedData) => {
  if(savedData && savedData[TABLE][0][type]== null){
  // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "9.1")
  pred.target.crossOrigin = "anonymous";
  // console.log(pred.target.naturalWidth, "pred", type, pred.target);
  if (pred.target.naturalHeight > 0 && pred.target.naturalWidth > 0) {
    // alert(pred.target.naturalHeight)
    let fin = null;
    const imageCan = document.createElement("canvas");
    let ctx = imageCan.getContext("2d");
    imageCan.style.width = 600 + "px";
    imageCan.style.height = 800 + "px";
    imageCan.width = Math.floor(600 * 1);
    imageCan.height = Math.floor(800 * 1);
    ctx.drawImage(
      pred.target,
      0,
      0,
      pred.target.naturalWidth,
      pred.target.naturalHeight,
      0,
      0,
      pred.target.naturalWidth,
      pred.target.naturalHeight
    );
    const imageData = ctx.getImageData(
      0,
      0,
      pred.target.naturalWidth,
      pred.target.naturalHeight
    );
    fin = tf.browser.fromPixels(imageData);
    // debugger
    tf.dispose(image[type])
    setLoaded({
      ...image,
      [type]: fin,
    });
    // tf.dispose(fin)
    // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "9.2")
  }
  }
};

export const getTensorFromImage = (src, image, setLoaded, type) => {
  let pred = new Image();
  pred.src = src;
  pred.crossOrigin = "anonymous";
  let fin = null;
  pred.onload = async () => {
    // console.log(pred);
    const imageCan = document.createElement("canvas");
    let ctx = imageCan.getContext("2d");
    imageCan.style.width = 600 + "px";
    imageCan.style.height = 800 + "px";
    imageCan.width = Math.floor(600 * 1);
    imageCan.height = Math.floor(800 * 1);
    ctx.drawImage(
      pred,
      0,
      0,
      pred.naturalWidth,
      pred.naturalHeight,
      0,
      0,
      pred.naturalWidth,
      pred.naturalHeight
    );
    const imageData = ctx.getImageData(
      0,
      0,
      pred.naturalWidth,
      pred.naturalHeight
    );
    // console.log(imageCan.toDataURL(), type)
    fin = tf.browser.fromPixels(imageData);
    // debugger

    // console.log(fin)
    setLoaded({
      ...image,
      [type]: fin,
    });
  };
  return fin;
};

export function resize(img, size, mask = false, flatten = true) {
  // console.log(tf.memory, "102.1")
  let frame_h = size[0];
  let frame_w = size[1];
  let image_h = img.shape.slice([1], [3])[0];
  let image_w = img.shape.slice([1], [3])[1];
  let h_r = frame_h / image_h;
  let w_r = frame_w / image_w;
  let r = Math.min(h_r, w_r);
  let dim = [Math.round(image_h * r), Math.round(image_w * r)];
  let resized;
  if (mask) {
    resized = tf.image.resizeNearestNeighbor(img, [800, 600], false, true);
    tf.dispose(img)
  } else {
    resized = tf.image.resizeBilinear(img, [dim[0], dim[1]]);
    tf.dispose(img)
  }
  // let delta_h = frame_h - resized.shape[1]
  // let delta_w = frame_w - resized.shape[2]
  // let top = Math.floor(delta_h / 2)
  // let bottom = delta_h - Math.floor(delta_h / 2)
  // let left = Math.floor(delta_w / 2)
  // let right = delta_w - Math.floor(delta_w / 2)
  // console.log(resized.shape)
  let z = tf.zeros([800, 600, 1]);
  let resized1 = tf.concat([resized, z], 2);
  tf.dispose(resized)
  tf.dispose(z)
  let resized2 = resized1
  if (flatten == true) {
    resized2 = tf.util.flatten(resized1.arraySync());
    tf.dispose(resized1)
  }
  // console.log(tf.memory, "102.2")
  // console.log(resized)
  // let new_im = resized.pad([[0, 0], [top, bottom], [left, right], [0, 0]])
  return resized2;
}

export const checkUniqueColor = (x, y, z) => {
  if(x == 255 && y == 85 && z== 0){
    return false
  }
  // else if(x == 255 && y == 85 && z== 0){
  //   return false
  // }
  else if(x == 255 && y == 170 && z== 0){
    return false
  }
  else if(x == 170 && y == 0 && z== 51){
    return false
  }
  else if(x == 170 && y == 0 && z== 118){
    return false
  }
  else if(x ==255&& y == 255 && z== 38){
    return false
  }
  return true
}
export const putImageDataCanvas = (canvas_id, imgData, setData) => {
  // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory(), "104.1")
  let c = document.getElementById(canvas_id);
  let ctx = c.getContext("2d");
  c.style.width = 600 + "px";
  c.style.height = 800 + "px";
  // var scale = 1;
  // window.devicePixelRatio;
  c.width = Math.floor(600 * 1);
  c.height = Math.floor(800 * 1);
  let data = ctx.getImageData(0, 0, 600, 800);
  // data.data = imgData
  for (let i = 0; i < data.data.length; i += 1) {
    data.data[i] = imgData[i];
    if ((i + 1) % 4 == 0) {
      data.data[i] = 255;
    }
  }
  ctx.putImageData(data, 0, 0);
  var dataURL = c.toDataURL();
  // console.log(dataURL);
  let img = new Image();
  img.src = dataURL;
  img.crossOrigin = "anonymous";
  setData(true);
  // console.log(tf.memory(), "104.2")
  return dataURL;
};

export const createFinalPrediction = async (
  activeVariable,
  newPred,
  previousTensors,
  setPreviousTensors,
  setOverlappedSeg,
  person_data,
  beforeBg
) => {
  console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "12.1")
  if (activeVariable.includes("overlapped")) {
    let list = [];
    await Object.keys(newPred).map((key) => {
      if (key.includes("_overlapped")) {
        if (newPred[key].length > 0) {
          list = list.concat(newPred[key]);
        }
      }
    });
    await Object.keys(previousTensors).map((key) => {
      // if(key.includes("_overlapped")){
      if (person_data[`${key}_overlapped_seg`]) {
        list.push(person_data[`${key}_overlapped_seg`]);
      }
      // }
    });
    let final = "";
    await mergeImages(list).then((b64) => {
      final = b64;
    });
    setOverlappedSeg(final);
    console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "12.2")
    return final;
  } 
  else if(activeVariable.includes("_only")){
    // let _ = tf.tidy(() => {
      let image = new Image();
      image.src = "";
      let prom = new Promise((resolve) => {
        // let image = new Image();
        let len = newPred[activeVariable].length - 1;
        image.src = newPred[activeVariable][len];
        image.onload = resolve();
      }).then(async () => {
        console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), " Inside only present ")
        // alert(newPred[activeVariable][len])
        let end = await activeVariable.indexOf("_only_seg");
        let variable = await activeVariable.substring(0, end);
        let segmentTensor = await tf.browser.fromPixels(image);
        // let finalTensors = await previousTensors;
        // let final = tf.tidy(() => {
          let sum_ = tf.sum(segmentTensor, -1);
          let sum_mask = tf.cast(sum_.equal(0), "int32");
          sum_mask = tf.expandDims(sum_mask, -1);
          // let final = tf.mul(sum_mask, previousTensors[variable]);
          // final = tf.add(final, segmentTensor)
          Object.keys(previousTensors).map(part => {
            let tempPart = tf.mul(sum_mask, previousTensors[part]);
            // tf.dispose(previousTensors[part])
            tf.dispose(previousTensors[part]);
            previousTensors[part] = tempPart
            // tf.dispose(tempPart)
          })
          tf.dispose(sum_mask)
          let final = tf.add(segmentTensor, previousTensors[variable]);
          tf.dispose(segmentTensor)
          // alert(variable)
          // debugger
          // console.log("Is full")
          // previousTensors[variable].print()
          // tf.dispose(previousTensors[variable])
          // console.log("Is null")
          // previousTensors[variable].print()
          previousTensors[variable] = final
          // console.log("Is assigned")
          // previousTensors[variable].print()
          // tf.dispose(final)
          // return final
          // })
          console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "12.3")
          // Object.keys(previousTensors).map(part => console.log(previousTensors[part]))
          await setPreviousTensors({
            ...previousTensors,
          });
          // await addToCanvas(previousTensors);
          // tf.dispose(previousTensors)

      })

      await prom
    // })
  }
  else {
    // alert("Cmes here")
    // alert(newPred[activeVariable])
    let image = new Image();
    image.src = "";
    let prom = new Promise((resolve) => {
      // let image = new Image();
      let len = newPred[activeVariable].length - 1;
      image.src = newPred[activeVariable][len];
      image.onload = resolve();
    }).then(async () => {
      console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "Inside async ")
      // alert(newPred[activeVariable][len])
      let end = await activeVariable.indexOf("_seg");
      let variable = await activeVariable.substring(0, end);
      let segmentTensor = await tf.browser.fromPixels(image);
      // debugger
      await tf.browser.toPixels(
        segmentTensor,
        document.getElementById("finalImage2")
      );
      let finalTensors = await previousTensors;
      // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "Before If")
      if (activeVariable !== "background_seg") {
        let final = tf.tidy(() => {
        let sum_ = tf.sum(segmentTensor, -1);
        let sum_mask = tf.cast(sum_.equal(0), "int32");
        sum_mask = tf.expandDims(sum_mask, -1);
        let final = tf.mul(sum_mask, previousTensors[variable]);
        final = tf.add(final, segmentTensor)
        return final
        })
        tf.dispose(previousTensors[variable])
        tf.dispose(finalTensors[variable]);
        finalTensors = {
          ...finalTensors,
          [variable]: final,
        };
        // await addToCanvas(finalTensors);
        tf.dispose(segmentTensor);
        
      }
      else{
          let final = tf.tidy(() => {
          let sum_ = tf.sum(segmentTensor, -1);
          let sum_mask = tf.cast(sum_.equal(0), "int32");
          sum_mask = tf.expandDims(sum_mask, -1);
          let final = tf.mul(sum_mask, previousTensors[beforeBg.split("_seg")[0]]);
          return final
          })

          // console.log(finalTensors, tensor, "Changing", "tensor");
          // tf.dispose(segmentTensor)
          tf.dispose(previousTensors[beforeBg.split("_seg")[0]])
          tf.dispose(finalTensors[beforeBg.split("_seg")[0]]);
          finalTensors = {
            ...finalTensors,
            [beforeBg.split("_seg")[0]]: final,
          };

      }

      
      tf.dispose(segmentTensor)
        //   tf.tidy(() => {
      // await addToCanvas(finalTensors);

      console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "segment tensor after")
      await setPreviousTensors({
        ...finalTensors,
      });
    });
    await prom;
  }
  console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "12.2")
};

export const addToCanvas = (finalTensors, setBackgroundPred, activeVariable, beforeBg, setChangePred) => {
    setChangePred(true)

    let allVariables = []
    console.log(activeVariable)
    if(activeVariable=="lla_seg") allVariables.push("lua")
    if(activeVariable=="rla_seg") allVariables.push("rua")
    if(activeVariable=="lua_seg") allVariables.push("lla")
    if(activeVariable=="rua_seg") allVariables.push("rla")
    if(activeVariable.includes("torso")) {
      // allVariables.push("lua")
      // allVariables.push("rua")
    }else{
      if(activeVariable.includes("background")){
        if(beforeBg=="lla_seg") allVariables.push("lua")
        if(beforeBg=="rla_seg") allVariables.push("rua")
        if(beforeBg=="lua_seg") allVariables.push("lla")
        if(beforeBg=="rua_seg") allVariables.push("rla")
        // if(beforeBg.includes("torso")) allVariables.push("rua")
        // if(beforeBg.includes("torso")) allVariables.push("lua")
        // allVariables.push("torso")
      }else{
        // if(!beforeBg.includes("torso")){
          allVariables.push("torso")
        // } 
      }
    }
    if(activeVariable.includes("background") && beforeBg.includes("torso")){
      allVariables = []
    }
    // if(activeVariable.includes("torso")){
    //   allVariables = []
    // }
    // allVariables.push("torso")
    allVariables.map((variable) => {
      tf.tidy(() => {
        tf.browser.toPixels(
          finalTensors[variable],
          document.getElementById(`${variable}_present`)
        );
      })
    })

    let allPartsSeg = []
    allVariables.map((variable) => {
      let finalImageCanvas = document.getElementById(`${variable}_present`)
      let ctx = finalImageCanvas.getContext("2d");
      allPartsSeg.push({src: finalImageCanvas.toDataURL(), opacity: 0.5})
      // console.log(finalImageCanvas.toDataURL())
    })
    // console.log(allPartsSeg[0])
    let final = ""
    mergeImages(allPartsSeg).then((b64) => {
      final = b64;
      // console.log(final, "Final one")
      // setBackgroundPred(final)

      var canvas = document.createElement('canvas');
      canvas.width = 600;
      canvas.height = 800;
      var ctx = canvas.getContext('2d');
      var tile = new Image();
      tile.src = final;
      tile.onload = function() {
        var buffer = document.createElement('canvas');
        var bufferctx = buffer.getContext('2d');
        buffer.width = 600;
        buffer.height = 800;
        bufferctx.drawImage(tile, 0, 0, tile.naturalWidth, tile.naturalHeight, 0, 0, 600, 800);
        var imageData = bufferctx.getImageData(0,0,600,  800);
        // console.log(buffer.toDataURL())
        var data = imageData.data;
        // console.log(data.length)
        var removeBlack = function() {
            for (var i = 0; i < imageData.data.length; i += 4) {
                // console.log(imageData.data[i], imageData.data[i + 1], imageData.data[i+2])
                // break;
                if(imageData.data[i]+ imageData.data[i + 1] + imageData.data[i + 2] === 0) { 
                    imageData.data[i + 3] = 0; // alpha
                }else{
                  imageData.data[i + 3] = 255;
                }
            } 
            ctx.putImageData(imageData, 0, 0); 
            // console.log(canvas.toDataURL());
        }; 
      removeBlack();
      setBackgroundPred(canvas.toDataURL())
      //     alert("iN HRE")
          // draw(tile, ctx);
          
      }


    })

    

}

function draw(img, ctx) {
  var buffer = document.createElement('canvas');
  var bufferctx = buffer.getContext('2d');
  bufferctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, 600, 800);
  var imageData = bufferctx.getImageData(0,0,600,  800);
  var data = imageData.data;
  var removeBlack = function() {
      for (var i = 0; i < data.length; i += 4) {
          if(data[i]+ data[i + 1] + data[i + 2] === 0) { 
              data[i + 3] = 0; // alpha
          }
      } 
      ctx.putImageData(imageData, 0, 0); 
      
  }; 
removeBlack(); 
}

export const createOverlapRegin = (imageTensor) => {
  let tempMask = {};
  Object.keys(imageTensor).map((im) => {
    if (imageTensor[im] !== null) {
      let sum_ = tf.sum(imageTensor[im], -1);
      let sum_mask = tf.cast(sum_.equal(0), "int32");
      tempMask[im] = sum_mask;
      // tf.browser.toPixels(
      //   imageTensor[im],
      //   document.getElementById("finalImage2")
      // )
    }
  });

  let temp = tf.cast(tf.zeros([800, 600]), "int32");
  let tempImage = tf.cast(tf.ones([800, 600, 3]), "int32") * 188;
  Object.keys(tempMask).map((im) => {
    console.log(tempMask[im].shape, im, "On mode change");
    temp = tf.add(temp, tempMask[im]);
  });
  temp = tf.expandDims(temp, -1);
  temp = tf.mul(temp, tempImage);
  tf.browser.toPixels(temp, document.getElementById("finalImage2"));
  // console.log(temp.shape, tf.unique(temp).print(), "int32")
};

/**
 * Load an image from a given URL
 * @param {String} url The URL of the image resource
 * @returns {Promise<Image>} The loaded image
 */
function loadImage(url) {
  /*
   * We are going to return a Promise which, when we .then
   * will give us an Image that should be fully loaded
   */
  return new Promise((resolve) => {
    /*
     * Create the image that we are going to use to
     * to hold the resource
     */
    const image = new Image();
    /*
     * The Image API deals in even listeners and callbacks
     * we attach a listener for the "load" event which fires
     * when the Image has finished the network request and
     * populated the Image with data
     */
    image.addEventListener("load", () => {
      /*
       * You have to manually tell the Promise that you are
       * done dealing with asynchronous stuff and you are ready
       * for it to give anything that attached a callback
       * through .then a realized value.  We do that by calling
       * resolve and passing it the realized value
       */
      resolve(image);
    });
    /*
     * Setting the Image.src is what starts the networking process
     * to populate an image.  After you set it, the browser fires
     * a request to get the resource.  We attached a load listener
     * which will be called once the request finishes and we have
     * image data
     */
    image.src = url;
  });
}

export const imageToTensor = async (
  activeVariable,
  newPred,
  previousTensors,
  setPreviousTensors
) => {
  if (activeVariable.includes("overlapped")) {
    // alert(activeVariable)
    let len = newPred[activeVariable].length - 1;
    // image.src = newPred[activeVariable][len];
    let image = await loadImage(newPred[activeVariable][len]).then((image) =>
      // console.log(image)
      {
        return image;
      }
    );

    let end = await activeVariable.indexOf("_overlapped_seg");
    let variable = await activeVariable.substring(0, end);
    let segmentTensor = await tf.browser.fromPixels(image);
    let finalTensors = await previousTensors;

    let sum_ = await tf.sum(segmentTensor, -1);

    //   let sum_ =  tf.sum(segmentTensor, -1);
    let sum_mask = await tf.cast(sum_.equal(0), "int32");
    sum_mask = await tf.expandDims(sum_mask, -1);
    let final = await tf.mul(sum_mask, finalTensors[variable]);
    finalTensors = await {
      ...finalTensors,
      [variable]: final,
    };

    finalTensors[variable] = await tf.add(
      finalTensors[variable],
      segmentTensor
    );
    // console.log("in")
    return finalTensors;
  } else {
    let len = newPred[activeVariable].length - 1;
    // image.src = newPred[activeVariable][len];
    let image = await loadImage(newPred[activeVariable][len]).then((image) =>
      // console.log(image)
      {
        return image;
      }
    );

    let end = await activeVariable.indexOf("_seg");
    let variable = await activeVariable.substring(0, end);
    let segmentTensor = await tf.browser.fromPixels(image);
    let finalTensors = await previousTensors;

    await Object.keys(previousTensors).map((tensor) => {
      let sum_ = tf.sum(segmentTensor, -1);
      let sum_mask = tf.cast(sum_.equal(0), "int32");
      sum_mask = tf.expandDims(sum_mask, -1);
      let final = tf.mul(sum_mask, previousTensors[tensor]);
      // console.log(finalTensors, tensor, "Changing", "tensor");
      finalTensors = {
        ...finalTensors,
        [tensor]: final,
      };
    });


    await tf.browser.toPixels(
      segmentTensor,
      document.getElementById("finalImage2")
    );

    if (activeVariable !== "background_seg") {
      finalTensors[variable] = await tf.add(
        finalTensors[variable],
        segmentTensor
      );
    }
    return finalTensors;
  }

};


export const imageToTensorNew = async (
  activeVariable,
  newPred,
  previousTensors,
  setPreviousTensors
) => {
  
  if (activeVariable.includes("overlapped")) {
    
  } else {
    // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory(),"10.1")
    let len = newPred[activeVariable].length - 1;
    let image = await loadImage(newPred[activeVariable][len]).then((image) =>
      {
        return image;
      }
    );

    let end = await activeVariable.indexOf("_seg");
    let variable = await activeVariable.substring(0, end);
    let segmentTensor = await tf.browser.fromPixels(image);
    let finalTensors = await previousTensors;
    tf.dispose(finalTensors[variable])
    // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory(), "10.2")
    return {
      ...finalTensors,
      [variable]: segmentTensor
    }
    
  }

};
