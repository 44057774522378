import React, { useEffect, useState, useRef } from "react";
import * as tf from "@tensorflow/tfjs";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  UPDATE_PERSON_ARM_SEGMENTATION,
  UPDATE_PERSON_HAIR_SEGMENTATION,
  UPDATE_PERSON_RIGHT_ARM_SEGMENTATION,
  GET_PERSON_IMAGE,
  UPDATE_APPROPRIATE,
  UPDATE_OVERLAP_PRESENT,
  GET_GRAPY_SEGMENTATION,
  UPDATE_PERSON_LEFT_ARM_SEGMENTATION,
  // UPDATE_PERSON_LEFT_BARE_ARM_SEGMENTATION,
  // UPDATE_PERSON_RIGHT_BARE_ARM_SEGMENTATION,
  UPDATE_DIFICULTY,
  UPDATE_ARM_POSITION,
  UPDATE_PERSON_BACKGROUND_SEGMENTATION,
} from "./api";
import LoadSegmentedImage from "../LoadSegmentedImg";
import { Radio, RadioGroup, Button, Select } from "@chakra-ui/core";
import mergeImages from "merge-images";
import moment from "moment";
import {
  linkChange_id,
  getTensorFromImage,
  resize,
  putImageDataCanvas,
  predictionOnload,
  createFinalPrediction,
  imageToTensor,
  imageToTensorNew,
  createOverlapRegin,
  addToCanvas
} from "../utils/utility";

import {
  allParts,
  UPDATE_PERSON,
  TABLE,
  PRED_GOOD_AVAILABLE,
  OUTPUT_BACKGROUND,
  OUTPUT_BACKGROUND_EXT,
  GET_IMAGE_URL,
  UPDATE_PERSON_NA,
  UPDATE_PRED_GOOD,
  INITIAL,
} from "../main";
import "./index.css";
import { parse } from "graphql";
import BlackImage from "../../images/data-dumps_black-800x600.png"
let SCALE = 1;
const OFFSET_X = 0;
const OFFSET_Y = 0;
const SIZE = 600;
let SIZEX = 600;
let SIZEY = 800;
let RATIO = SIZEX / SIZEY;
let imageObj;
let LEFT_POSITION = "1700px";
let TOP_POSITION = "60px";



function rgbToHex(r, g, b) {
  if (r > 255 || g > 255 || b > 255) throw "Invalid color component";
  return ((r << 16) | (g << 8) | b).toString(16);
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
}

function wtb(data, color = 255) {
  if (color === 256) {
    for (let i = 0; i < data.length; i += 4) {
      data[i + 3] = 0;
    }
  } else {
    for (let i = 0; i < data.length; i += 4) {
      // let hex = rgbToHex(data[i], data[i + 1], data[i + 2]);
      // if (hex !== "ff5500") {
      //   data[i + 3] = 0;
      // }
      // if (
      //   data[i] > 200 &&
      //   data[i + 1] > 70 &&
      //   data[i + 1] < 90 &&
      //   data[i + 2] < 20
      // ) {
      //   data[i + 3] = 255;
      // }
      // else if(
      //   data[i] > 200 &&
      //   data[i + 1] > 150 &&
      //   data[i + 1] < 200 &&
      //   data[i + 2] < 20
      // ){
      //   data[i + 3] = 255;
      // }
      // else if(
      //   data[i] > 200 &&
      //   data[i + 1] > 150 &&
      //   data[i + 1] < 200 &&
      //   data[i + 2] < 20
      // ){
      //   data[i + 3] = 255;
      // }
      // else {
      //   data[i + 3] = 0;
      // }
      if (
        data[i] < 30 &&
        data[i + 1] < 30 &&
        // data[i + 1] < 200 &&
        data[i + 2] < 20
      ) {
        data[i + 3] = 0;
      }
    }
  }
}

const MapPoints = (props) => {
  // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory())
  // tf.tidy(() => {


  const {
    image,
    load,
    setPicData,
    product_id,
    image_id,
    extraPic,
    canvas_name,
    segmentationData,
    nextImage,
    original_image,
    id,
  } = props;
  let canvas = document.getElementById(canvas_name);
  imageObj = document.getElementById("image");
  const [saveInitialization, setSaveInitialization] = useState(false)
  const [dataUrl, setDataUrl] = useState("");
  const [preview, setPreview] = useState(false);
  const [opacity, setOpacity] = useState(5);
  const [refreshFull, setRefreshFull] = useState(false)
  const [saveOverlapPresent] = useMutation(UPDATE_OVERLAP_PRESENT);
  const [saveAppropriate] = useMutation(UPDATE_PERSON_NA, {
    onCompleted(data) {
      // console.log("onCompleted: ", data?.update_grapy_correction_14_part.returning[0].is_na)
      // alert(data?.update_grapy_correction_14_part.returning[0].is_na)
      if (data?.[`update_${TABLE}`].returning[0].is_na == "Yes") {
        disposeAll()
        nextImage();
      } else {
        alert("Image marked as good! Please do the segmentation above.");
      }
    },
  });
  const [toggleIgnoreBg, setToggleIgnoreBg] = useState("no");
  const [showRightCoveredArm, setShowRightCoveredArm] = useState(true);
  const [showBackground, setShowBackground] = useState(true);
  const [showLeftBareArm, setShowLeftBareArm] = useState(true);
  const [showRightBareArm, setShowRightBareArm] = useState(true);
  const [showLeftCoveredArm, setShowLeftCoveredArm] = useState(true);
  const [opacitySeg, setOpacitySeg] = useState(6);
  const [showTorso, setShowTorso] = useState(true);
  const [listMergedImages, setListMergedImages] = useState([]);
  const [initTime, setInitTime] = useState(moment());
  const [predictionData, setPredictionData] = useState();
  const [showSave, setShowSave] = useState(false);
  const [update, setUpdate] = useState(false);
  const [badDensepose, setBadDensepose] = useState("No");
  const [showOneSeg, setShowOneSeg] = useState("Yes");
  const [imageTensor, setImageTensor] = useState(initializeImageTensor());
  const [showSegReview, setShowSegReview] = useState("")
  const [backgroundPred, setBackgroundPred] = useState("")
  const [changePred, setChangePred] = useState(false);
  function initializeImageTensor() {
    // debugger
    // if(imageTensor && imageTensor["torso"]!=null){
    //   tf.dispose(imageTensor["torso"]);
    // }

    // tf.dispose(imageTensor["lla"]);
    // tf.dispose(imageTensor["lua"]);
    // tf.dispose(imageTensor["rla"]);
    // tf.dispose(imageTensor["rua"]);
    // tf.dispose(imageTensor["collar"]);
    // tf.dispose(imageTensor["straps"]);
    return {
      torso: null,
      lua: null,
      rua: null,
      lla: null,
      rla: null,
      collar: null,
      // straps: null,
    }
  }

  const [timageTensor, settImageTensor] = useState({
    torso: null,
    lua: null,
    rua: null,
    lla: null,
    rla: null,
    collar: null,
    // straps: null,
  });
  const [finalPrediction, setFinalPrediction] = useState();
  const [data, setData] = useState();
  const [loadOnce, setLoadOnce] = useState(true);
  const [radioChange, setRadioChange] = useState(true);
  const [mode, setMode] = useState("full");
  const [overlappedSeg, setOverlappedSeg] = useState("")

  let DEFAULT_VALUES = {};
  allParts.map((one) => {
    DEFAULT_VALUES[one["variable"]] = "";
  });
  let BINARY_DEFAULT_VALUES = {};
  allParts.map((one) => {
    BINARY_DEFAULT_VALUES[one["variable"]] = "scratch";
  });
  const [formFields, setFormFields] = useState(DEFAULT_VALUES);
  const [imageToMerge, setImageToMerge] = useState({});
  const [edgeImageToMerge, setEdgeImageToMerge] = useState({});

  const onInputChange = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };
  let showTemp = {};
  for (let i = 0; i < allParts.length; i += 1) {
    showTemp[allParts[i]["variable"]] = true;
  }

  const [show, setShow] = useState(showTemp);

  useEffect(() => {
    if (props.loadImage == "segment") {
      setOpacity(10);
    } else {
      setOpacity(5);
    }
  }, [props.loadImage]);

  const { loading, data: person_data } = useQuery(GET_IMAGE_URL, {
    variables: {
      id: id,
    },
    onCompleted(data) {
      setLoadOnce(true);
    },
  });


  const [savePredGood] = useMutation(UPDATE_PRED_GOOD, {
    refetchQueries: [{ query: GET_IMAGE_URL, variables: { id: id } }],
  });
  useEffect(() => {
    let fields = {};
    for (const property in DEFAULT_VALUES) {
      fields[property] = person_data[property] || DEFAULT_VALUES[property];
    }
    setFormFields(fields);
    setNaReason(
      person_data[TABLE][0].reason_for_na == null
        ? "No"
        : person_data[TABLE][0].reason_for_na
    );
    // setEdgeImageToMerge(init)
    // createFinalPrediction()
    createOverlappedWork()
    setLoadOnce(true);
  }, [person_data]);
  // const [value, setValue] = React.useState(person_data ? person_data && person_data.tucked_in_segmentation[0].difficulty ?  person_data.tucked_in_segmentation[0].difficulty: 0: 0);
  const [leftArmPosition, setLeftArmPosition] = React.useState("Front");
  const [rightArmPosition, setRightArmPosition] = React.useState("Front");
  const [save_arm_position] = useMutation(UPDATE_ARM_POSITION, {
    onError(err) {
      alert(
        `The arm positions were not saved. Please check your internet connection. For technical purpose: ${err}`
      );
    },
  });

  const [save_all, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_PERSON, {
      onCompleted(data) {
        // setPicData(dataUrl);
        if (mutateType == "saved") {
          window.location.reload();
        } else {
          setSavedImg(true);
          setShow(false);
          setSaveInitialization(false);
        }
      },
      onError(data) {
        alert(
          `Your segmentations did not save. Please check your internet connection.\n For technical purpose: ${data} `
        );
      },
      refetchQueries: [
        {
          query: GET_IMAGE_URL,
          variables: {
            id: id,
          },
        },
      ],
    });

  const [points, setPoints] = useState([[]]);
  const [pointsColor, setPointsColor] = useState([
    { colorSave: "white", colorView: "#8ED6FF", colorPreview: "#aaaaaa" },
  ]);

  const [lastPoint, setLastPoint] = useState({});
  const [penultimatePoint, setPenultimatePoint] = useState({});
  const [toggle, setToggle] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const canvasRef = useRef(null);
  const [savedImg, setSavedImg] = useState(false);
  const [activeVariable, setActiveVariable] = useState("torso");
  const [savingPart, setSavingPart] = useState(false);
  const [naReason, setNaReason] = useState("No");
  const [mutateType, setMutateType] = useState("saved");
  const [ignorePart, setIgnorePart] = useState("");
  const [beforeBg, setBeforeBg] = useState("torso")
  const [modValue, setModValue] = useState(0)
  // setModValue(modValue + 1)
  // let topBottomInit = {}
  // allParts.map(part => {
  //   if(part.radio){
  //     topBottomInit[part.variable.split("_seg")[0] + "_orientation"] = "top"
  //   }
  // })
  // console.log(topBottomInit, "init")
  const [topBottom, setTopBottom] = useState({});
  useEffect(() => {
    canvas = document.getElementById(canvas_name);
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "black";
  }, []);

  useEffect(() => {
    canvas = document.getElementById(canvas_name);
    const instaCanvas = canvas;
    let ctx = instaCanvas.getContext("2d");
    canvas.style.width = SIZEX + "px";
    canvas.style.height = SIZEY + "px";
    var scale = 1;
    // window.devicePixelRatio;
    canvas.width = Math.floor(SIZEX * scale);
    canvas.height = Math.floor(SIZEY * scale);
    ctx.scale(scale, scale);
  }, [SIZEX, SIZEY]);

  // useEffect(() => {
  //   // alert('here')
  //   if (points.length > 1 && points[points.length - 2].length == 0) {
  //     canvas = document.getElementById(canvas_name);
  //     const instaCanvas = canvas;
  //     let ctx = instaCanvas.getContext("2d");
  //     canvas.style.width = SIZEX + "px";
  //     canvas.style.height = SIZEY + "px";
  //     var scale = 1;
  //     // window.devicePixelRatio;
  //     canvas.width = Math.floor(SIZEX * scale);
  //     canvas.height = Math.floor(SIZEY * scale);
  //     ctx.scale(scale, scale);
  //     if (activeIndex >= 0) {
  //       for (let j = 0; j < points.length; j++) {
  //         ctx.beginPath();
  //         if (points[j].length > 0) {
  //           ctx.moveTo(points[j][0].x, points[j][0].y);
  //         }
  //         for (let i = 1; i < points[j].length; i++) {
  //           // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
  //           ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
  //         }
  //         ctx.lineWidth = 2;
  //         ctx.fillStyle = pointsColor[j].colorPreview;
  //         // debugger
  //         ctx.strokeStyle = pointsColor[j].colorPreview;
  //         ctx.fill();
  //         ctx.stroke();
  //       }
  //     }
  //     const presentData = canvas.toDataURL();
  //     let list = [presentData];
  //     Object.keys(imageToMerge).forEach((key) => {
  //       if (imageToMerge[key] == "save") {
  //         list.push(person_data[TABLE][0][key]);
  //       }
  //     });
  //     createImage(ctx, list);
  //     ctx.beginPath();
  //     ctx.stroke();
  //     // alert('Enters')
  //   }
  // }, [imageToMerge]);

  useEffect(() => {
    let topBottomInit = {};
    allParts.map((part) => {
      if (part.radio) {
        let varName = part.variable.split("_seg")[0] + "_orientation";
        topBottomInit[varName] = person_data[TABLE][0][varName];
      }
    });
    // console.log(topBottomInit, "init");
    setTopBottom(topBottomInit);
  }, [person_data]);

  useEffect(() => {
    // if (typeof lastPoint == "object" && Object.keys(lastPoint).length > 0) {
    // console.log

    if (person_data && !mutationLoading) {
      canvas = document.getElementById(canvas_name);
      // console.log(canvas.toDataURL())
      const instaCanvas = canvas;
      let ctx = instaCanvas.getContext("2d");
      canvas.style.width = SIZEX + "px";
      canvas.style.height = SIZEY + "px";
      var scale = 1;
      // window.devicePixelRatio;
      canvas.width = Math.floor(SIZEX * scale);
      canvas.height = Math.floor(SIZEY * scale);
      ctx.scale(scale, scale);

      let list = [];
      // Object.keys(allParts).forEach((key) => {
      //   // if (imageToMerge[key["variable"]] != "scratch" && person_data[TABLE][0][key["variable"]]!=null) {
      //   //   list.push(person_data[TABLE][0][key["variable"]]);
      //   // }
      //   // console.log(key)
      //   console.log(imageToMerge)
      //   if(imageToMerge[allParts[key]["variable"]] && imageToMerge[allParts[key]["variable"]] != "scratch"){
      //     // alert(imageToMerge[allParts[key]["variable"]])
      //     list.push(person_data[TABLE][0][allParts[key]["variable"]])
      //   }
      // });

      Object.keys(allParts).forEach((key) => {
        // if (imageToMerge[key["variable"]] != "scratch" && person_data[TABLE][0][key["variable"]]!=null) {
        //   list.push(person_data[TABLE][0][key["variable"]]);
        // }
        // console.log(key)
        if (
          showOneSeg == "Yes" &&
          allParts[key]["variable"] != activeVariable
        ) {
          // alert(allParts[key]["variable"], activeVariable)
          return null;
        }
        if (
          imageToMerge[allParts[key]["variable"]] &&
          imageToMerge[allParts[key]["variable"]] != "scratch"
        ) {
          // alert(imageToMerge[allParts[key]["variable"]])
          list.push(person_data[TABLE][0][allParts[key]["variable"]]);
        } else if (
          imageToMerge[allParts[key]["variable"]] &&
          imageToMerge[allParts[key]["variable"]] != "scratch" &&
          person_data[TABLE][0][allParts[key]["variable"]] !== null
        ) {
          list.push(person_data[TABLE][0][allParts[key]["variable"]]);
        } else {
          if (!imageToMerge[allParts[key]["variable"]]) {
            list.push(person_data[TABLE][0][allParts[key]["variable"]]);
          }
        }
      });

      // Object.keys(edgeImageToMerge).forEach((key) => {
      //   if (showOneSeg == "Yes" && key != activeVariable) {
      //     return null;
      //   }
      //   if (edgeImageToMerge[key]) {
      //     list = list.concat(edgeImageToMerge[key]);
      //   }
      // });
      createImage(ctx, list);
      // make_base(ctx, person_data[TABLE][0][activeVariable])
      if (activeIndex > 0) {
        for (let j = 0; j < points.length - 1; j++) {
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            ctx.fillRect(
              points[j][i].x - OFFSET_X,
              points[j][i].y - OFFSET_Y,
              3,
              3
            );
            // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = 2;
          ctx.globalAlpha = 0.7;
          ctx.fillStyle = pointsColor[j].colorView;
          ctx.fill();
          ctx.stroke();
        }
      }
      if (penultimatePoint) {
        ctx.beginPath();
        if (points[activeIndex].length > 0) {
          ctx.moveTo(points[0].x, points[0].y);
          ctx.fillRect(
            points[activeIndex][0].x - OFFSET_X,
            points[activeIndex][0].y - OFFSET_Y,
            3,
            3
          );
        }
        for (let i = 0; i < points[activeIndex].length; i++) {
          ctx.fillRect(
            points[activeIndex][i].x - OFFSET_X,
            points[activeIndex][i].y - OFFSET_Y,
            3,
            3
          );
          // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
          ctx.lineTo(
            points[activeIndex][i].x - OFFSET_X,
            points[activeIndex][i].y - OFFSET_Y
          );
        }
        // Commenting this because final segment is getting stored in some other form.
        // if (toggle) {
        //   ctx.lineWidth = 2;
        //   ctx.globalAlpha = 0.7;
        //   ctx.fillStyle = pointsColor[activeIndex].colorView;
        //   ctx.fill();
        // }
        ctx.stroke();
      }
      // }
    }
    if (activeVariable) {
      // previewSegmentedImage();
    }
  }, [
    lastPoint,
    activeVariable,
    imageToMerge,
    person_data,
    opacity,
    update,
    opacitySeg,
  ]);

  function getMousePos(canvas, evt) {
    const rect = canvasRef.current.getBoundingClientRect();
    return {
      x: evt.clientX - rect.x,
      y: evt.clientY - rect.y,
    };
  }

  const capturePoint = (evt) => {
    if (activeIndex == 0) {
      alert("Please select the body part that you want to color");
      return;
    }
    const mousePos = getMousePos(canvasRef, evt);
    const temp = points;
    temp[activeIndex].push(mousePos);
    setPoints(temp);
    setPenultimatePoint(lastPoint);
    setLastPoint(mousePos);
    setToggle(false);
  };

  const lastPointClick = () => {
    // debugger;
    setToggle(true);
    let temp = points;
    let tempPoints = points[activeIndex];
    tempPoints = [...tempPoints, tempPoints[0]];
    temp[activeIndex] = tempPoints;
    temp.push([[]]);
    let tempColors = pointsColor;
    tempColors = [...tempColors, pointsColor[activeIndex]];
    setPoints(temp);
    setPointsColor(tempColors);
    setLastPoint(points[activeIndex][0]);
    setActiveIndex(activeIndex + 1);
    setIgnorePart(pointsColor[activeIndex]["colorSave"]);
    // previewSegmentedImage();
    // console.log(pointsColor[activeIndex], "reaches here")
  };

  const deletePointClick = () => {
    const temp = points;
    if (points[activeIndex].length === 1) {
      if (activeIndex > 0) {
        const temp = points.slice(0, points.length - 1);
        const tempColor = pointsColor.slice(0, pointsColor.length - 1);
        setToggle(false);
        setPoints([...temp]);
        setPointsColor([...tempColor]);
        setLastPoint(
          points[activeIndex - 1][points[activeIndex - 1].length - 1]
        );
        setActiveIndex(activeIndex - 1);
        setUpdate(!update);
      } else {
        const tempColor = pointsColor.slice(0, pointsColor.length - 1);
        setActiveIndex(0);
        setPoints([[]]);
        setPointsColor([...tempColor]);
        setToggle(false);
        setLastPoint({});
      }
    } else {
      const tempPoints = points[activeIndex].slice(
        0,
        points[activeIndex].length - 1
      );
      temp[activeIndex] = tempPoints;
      setToggle(false);
      setPoints([...temp]);
      setLastPoint(points[activeIndex][points[activeIndex].length - 2]);
    }
  };

  const showClicked = (part) => {
    let showTemp = {};
    if (part == "all") {
      for (let i = 0; i < allParts.length; i += 1) {
        showTemp[allParts[i]["variable"]] = true;
      }
    } else {
      for (let i = 0; i < allParts.length; i += 1) {
        showTemp[allParts[i]["variable"]] = false;
      }
      showTemp[part] = true;
    }
    setShow(showTemp);
  };

  const createOverlappedWork = async () => {
    let list = [];
    let final;
    for (let i = 0; i < allParts.length; i += 1) {
      let part = allParts[i]
      if (part["variable"].includes("_overlapped")) {
        if (person_data[TABLE][0][part["variable"]]) {
          list.push(person_data[TABLE][0][part["variable"]]);
        }
        // alert(typeof list)
        if (edgeImageToMerge[part["variable"]]) {
          list = await list.concat(edgeImageToMerge[part["variable"]])
        }
      }
    }
    await mergeImages(list).then((b64) => {
      final = b64
      setOverlappedSeg(final)

    })

    return final
  }

  const imageTensorFull = () => {
    // if(imageTensor)
    // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 13")
    return tf.tidy(() => {


      let flag = true;
      // flag = tf.tidy((flag) => {
      Object.keys(imageTensor).forEach((key) => {
        if (imageTensor[key] == null) {
          flag = false;
        }
      });
      // return flag
      // })
      // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 13.1")
      return flag;
    })
  };

  // useEffect(() => {
  // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 11")
  //   return () => {
  //     // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 11.1")
  //     // console.log(tf.engine())
  //     // debugger
  //     // imageTensor["torso"].print()
  //     // tf.dispose(imageTensor["torso"]);
  //     // tf.dispose(imageTensor["lla"]);
  //     // tf.dispose(imageTensor["lua"]);
  //     // tf.dispose(imageTensor["rla"]);
  //     // tf.dispose(imageTensor["rua"]);
  //     // tf.dispose(imageTensor["collar"]);
  //     // tf.dispose(imageTensor["straps"]);
  //     // tf.dispose(imageTensor)
  //     // tf.dispose(timageTensor["torso"])
  //     // tf.dispose(timageTensor["lla"]);
  //     // tf.dispose(timageTensor["lua"]);
  //     // tf.dispose(timageTensor["rla"]);
  //     // tf.dispose(timageTensor["rua"]);
  //     // tf.dispose(timageTensor["collar"]);
  //     // tf.dispose(timageTensor["straps"]);
  //     // tf.dispose(timageTensor)
  //     // tf.disposeVariables()
  //     // imageTensor["torso"].print()
  //     // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 11.2")
  //   }
  // }, [])

  const disposeAll = () => {
    tf.dispose(imageTensor["torso"]);
    tf.dispose(imageTensor["lla"]);
    tf.dispose(imageTensor["lua"]);
    tf.dispose(imageTensor["rla"]);
    tf.dispose(imageTensor["rua"]);
    tf.dispose(imageTensor["collar"]);
    // tf.dispose(imageTensor["straps"]);
    tf.dispose(imageTensor)
    tf.dispose(timageTensor["torso"])
    tf.dispose(timageTensor["lla"]);
    tf.dispose(timageTensor["lua"]);
    tf.dispose(timageTensor["rla"]);
    tf.dispose(timageTensor["rua"]);
    tf.dispose(timageTensor["collar"]);
    // tf.dispose(timageTensor["straps"]);
    tf.dispose(timageTensor)
  }
  useEffect(() => {
    // alert('enters')
    // debugger;
    // if(mode)
    if (imageTensorFull()) {
      // alert("enters after image tensor full")
      // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 109.1")
      let finalResultant = tf.tidy(() => {
        if (loadOnce && person_data) {
          // debugger;
          let tempImageTensor = imageTensor;

          async function create(variable, tempImageTensor) {
            if (person_data[TABLE][0][variable]) {
              let finalhere;
              let init = {};
              init[variable] = [person_data[TABLE][0][variable]];
              let pp;
              await imageToTensorNew(
                variable,
                init,
                tempImageTensor,
                setImageTensor
              ).then((p) => {
                pp = p;
              });
              return pp;
            }
          }
          let dum = {};
          (async (dum, tempImageTensor) => {
            for (let i = 0; i < allParts.length; i += 1) {
              if (!allParts[i]["variable"].includes("_overlapped")) {
                if (person_data[TABLE][0][allParts[i]["variable"]]) {
                  tempImageTensor = await create(
                    allParts[i].variable,
                    tempImageTensor
                  );
                }
              }
            }
            tf.dispose(timageTensor)
            settImageTensor(tempImageTensor);
          })(dum, tempImageTensor);

          setLoadOnce(false);
        }
        // let final = null;
        //   let union_cumulative = tf.zeros([800, 600, 3], "int32")
        //   let intersection_cumulative = tf.zeros([800, 600, 3], "int32")

        //   for (let i = 0; i < masks.length; i++) {
        //     let new_intersection = getIntersection(union_cumulative, masks[i])
        //     let previous_intersection = intersection_cumulative
        //     intersection_cumulative = tf.add(intersection_cumulative, new_intersection)
        //     tf.dispose(previous_intersection)
        //     tf.dispose(new_intersection)
        //     let previous_union = union_cumulative
        //     union_cumulative = tf.add(previous_union, masks[i])
        //     tf.dispose(masks[i])
        //     tf.dispose(previous_union)
        //  }

        //  intersection_cumulative_bool = tf.cast(intersection_cumulative, "bool")
        //  tf.dispose(intersection_cumulative)
        //  intersection_cumulative_int = tf.cast(intersection_cumulative_bool, "int32")
        //  tf.dispose(intersection_cumulative_bool)
        //  let result  = tf.mul(intersection_cumulative_int, union_cumulative)

        //  tf.dispose(intersection_cumulative_int)
        //   tf.dispose(union_cumulative)
        //   result.print()
        //   tf.dispose(result)



        if ((loadOnce && person_data) || mode === "overlapped" || saveInitialization || refreshFull === true ) {
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 2")
          // console.log(imageTensor["torso"], imageTensor["lla"], "In this")
          const final = tf.add(imageTensor["torso"], imageTensor["lla"], imageTensor);
          console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 2.1")
          let finalIntersection = getIntersection(imageTensor["torso"], imageTensor["lla"])
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 2.2")
          let final1 = tf.mod(final, 400);
          tf.dispose(final)
          // debugger
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 2.3  - 2 : final1, finalIntersection")
          let finalIntersection1 = tf.mul(finalIntersection, final1)
          tf.dispose(finalIntersection)
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 2.3.1  - 2 : final1, finalIntersection1")
          let finalIntersection2 = tf.mod(finalIntersection1, 400)
          tf.dispose(finalIntersection1)
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 2.3.2  - 2 : final1, finalIntersection2")
          let finalIntersection3 = getIntersection(final1, imageTensor["rua"])
          let final2 = tf.add(final1, imageTensor["rua"]);
          tf.dispose(final1)
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 2.3.3  - 3 : final2, finalIntersection2,finalIntersection3 ")
          let finalIntersection4 = tf.mod(tf.mul(finalIntersection3, final2), 155)
          // finalIntersection4 = tf.mod(finalIntersection4, 155)
          let result1 = tf.add(finalIntersection4, finalIntersection2)
          tf.dispose(finalIntersection4)
          tf.dispose(finalIntersection2)
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 2.4")

          let finalIntersection5 = getIntersection(final2, imageTensor["lua"])
          let final3 = tf.add(final2, imageTensor["lua"]);
          tf.dispose(final2)
          let finalIntersection6 = tf.mul(finalIntersection5, final3)
          let result2 = tf.add(result1, finalIntersection6)
          tf.dispose(finalIntersection6)
          tf.dispose(finalIntersection5)
          tf.dispose(result1)
          tf.dispose(finalIntersection2)
          let finalIntersection7 = getIntersection(final3, imageTensor["rla"])
          let final4 = tf.add(final3, imageTensor["rla"]);
          // tf.dispose(final4)
          tf.dispose(final3)
          let finalIntersection8 = tf.mul(finalIntersection7, final4)
          tf.dispose(finalIntersection7)
          tf.dispose(final4)
          let result = tf.add(result2, finalIntersection8)
          tf.dispose(result2)
          tf.dispose(finalIntersection8)



          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 3")
          let final6 = result
          // tf.dispose(result)
          let final7 = tf.mod(final6, 255);
          tf.dispose(final6)
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 4")
          // let full1 = imageTensor["torso"]
          let full1 = tf.zerosLike(imageTensor["torso"])
          let full2 = tf.add(full1,imageTensor["collar"])
          // tf.add(imageTensor["torso"], imageTensor["lla"])
          // tf.tidy(() => (tf.add(imageTensor["torso"], imageTensor["lla"])))
          tf.dispose(full1)
          let full3 = 
          // tf.add(full2, imageTensor["rla"])
          tf.mod(tf.add(full2, imageTensor["rla"]), 255)
          tf.dispose(full2)
          let full4 = tf.add(full3, imageTensor["lla"])
          tf.dispose(full3)
          let full5 = tf.add(full4, imageTensor["lua"])
          tf.dispose(full4)
          let full6 = tf.mod(tf.add(full5, imageTensor["rua"]), 255)
          tf.dispose(full5)
          let full = tf.add(full6, imageTensor["torso"])
          tf.dispose(full6)
          // let full = tf.add(full6, imageTensor["straps"])
          // tf.dispose(full6)
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 5")
          // // tf.dispose(full6)
          // let full = imageTensor["torso"]
          // Object.keys(imageTensor).map((key) => {
          //   if(key!=="background" && key!=="torso"){
          //     full = tf.add(imageTensor[key],full)
          //     // tf.dispose(full)
          //   }
          // })
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 5.1")
          tf.tidy(() => {
            tf.browser.toPixels(
              tf.cast(tf.mod(full, (255 - modValue*10)), "int32"),
              document.getElementById(`full_present`)
            );
          })
          tf.dispose(full)
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 5.1")
          let final8 = resize(final7, 600, 800, true);
          tf.dispose(final7)
          let final9 = putImageDataCanvas("finalImage", final8, setData);
          tf.dispose(final8)
          tf.tidy(() => {
            tf.browser.toPixels(
              imageTensor["torso"],
              document.getElementById("torso_present")
            );
          })
          tf.tidy(() => {
            tf.browser.toPixels(
              imageTensor["rua"],
              document.getElementById("rua_present")
            );
          })
          tf.tidy(() => {
            tf.browser.toPixels(
              imageTensor["lua"],
              document.getElementById("lua_present")
            );
          })
          tf.tidy(() => {
            tf.browser.toPixels(
              imageTensor["lla"],
              document.getElementById("lla_present")
            );
          })
          tf.tidy(() => {
            tf.browser.toPixels(
              imageTensor["rla"],
              document.getElementById("rla_present")
            );
          })
          tf.tidy(() => {
            tf.browser.toPixels(
              imageTensor["collar"],
              document.getElementById("collar_present")
            );
          })
          // tf.tidy(() => {
          // tf.browser.toPixels(
          //   imageTensor["straps"],
          //   document.getElementById("straps_present")
          // );
          // })
          tf.dispose(finalPrediction)
          setFinalPrediction(final9);
          tf.dispose(final9)
          setRefreshFull(false)
          // setChangePred(false)
          // addToCanvas(imageTensor, setBackgroundPred, activeVariable, beforeBg, setChangePred)
          // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "memory 6")
          
        }
      })

      // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory()["numDataBuffers"],tf.memory()["unreliable"], tf.memory(), "109.2")
      // tf.tidy()
    }
    
  }, [person_data, imageTensor, mode, saveInitialization, refreshFull, activeVariable, changePred]);

  useEffect(() => {
    if(imageTensorFull()){
    tf.tidy(() => {
      tf.browser.toPixels(
        imageTensor["torso"],
        document.getElementById("torso_present")
      );
    })
    tf.tidy(() => {
      tf.browser.toPixels(
        imageTensor["rua"],
        document.getElementById("rua_present")
      );
    })
    tf.tidy(() => {
      tf.browser.toPixels(
        imageTensor["lua"],
        document.getElementById("lua_present")
      );
    })
    tf.tidy(() => {
      tf.browser.toPixels(
        imageTensor["lla"],
        document.getElementById("lla_present")
      );
    })
    tf.tidy(() => {
      tf.browser.toPixels(
        imageTensor["rla"],
        document.getElementById("rla_present")
      );
    })
    tf.tidy(() => {
      tf.browser.toPixels(
        imageTensor["collar"],
        document.getElementById("collar_present")
      );
    })

    addToCanvas(imageTensor, setBackgroundPred, activeVariable, beforeBg, setChangePred)
    
    }
    
  }, [changePred, activeVariable, imageTensor]);

  // useEffect(() => {
  //   if(imageTensorFull()){
      
  //   }
  // }, [imageTensor, timageTensor, refreshFull])

  // console.log(tf.getBackend())

  useEffect(() => {
    // alert(activeVariable)
    // console.log(tf.memory(), "memory 100.1")
    if (!activeVariable.includes("overlapped") && !(activeVariable === "background_seg") && !(activeVariable.includes("only"))) {
      if (imageTensorFull()) {
        tf.browser.toPixels(
          imageTensor[activeVariable.split('_seg')[0]],
          document.getElementById('image1')
        )
        // tf.browser.toPixels(
        //   imageTensor[activeVariable.split('_seg')[0]],
        //   document.getElementById('image')
        // )
      }
    }
    if ((activeVariable === "background_seg")) {
      if (imageTensorFull()) {
        tf.browser.toPixels(
          imageTensor[beforeBg.split('_seg')[0]],
          document.getElementById('image1')
        )
        tf.browser.toPixels(
          imageTensor[beforeBg.split('_seg')[0]],
          document.getElementById('image')
        )
      }
    }
    // console.log(tf.memory(), "100.2")
  }, [activeVariable, imageTensor, predictionData])

  useEffect(() => {
    // alert(mode)
    if (mode == "overlapped") {
      if (!activeVariable.includes("_overlapped")) {
        setActiveVariable(activeVariable + "_overlapped_seg")
        setToggleIgnoreBg("yes")
      }
      var c = document.getElementById("image1");

      var ctx = c.getContext("2d");
      // console.log(finalPrediction)
      let base_image = new Image();
      base_image.src = finalPrediction;
      base_image.onload = () => ctx.drawImage(base_image, 0, 0);
      // createOverlappedWork()
    } else {
      if (activeVariable.includes("_overlapped")) {
        setActiveVariable(activeVariable.split("_overlapped")[0])
        setToggleIgnoreBg("no")
      }
      if (activeVariable.includes("_only")) {
        setActiveVariable(activeVariable.split("_only")[0])
        setToggleIgnoreBg("no")
      }
    }
    // createOverlapRegin(imageTensor)

  }, [mode, imageTensor, finalPrediction])

  useEffect(() => {
    if (!loadOnce) {
      // console.log(timageTensor, "dummy update");
      // tf.dispose(imageTensor)
      setImageTensor(timageTensor);
    }
  }, [loadOnce, timageTensor]);



  function getIntersection(mask1, mask2) {
    // console.log(tf.memory(),"101.1")
    const sum = tf.mul(mask1, mask2)
    // tf.dispose(mask1)
    // tf.dispose(mask2)
    const bool_sum = tf.cast(sum, "bool")
    tf.dispose(sum)
    const intmask = tf.cast(bool_sum, "int32")
    tf.dispose(bool_sum)
    // intmask.print()
    // console.log(tf.memory(),"102.1")
    return intmask;
  }

  const livePreview = async () => {
    // console.log("allParts: ", allParts);

    let list = [];
    for (let i = 0; i < allParts.length; i++) {
      let seg = person_data[TABLE][0][allParts[i]["variable"]];
      if (seg != null || seg != undefined) {
        list.push(seg);
      }
    }
    // console.log("list: ", list.length, list)

    // await toDataURL(
    //   image,
    //   async function(dataUrl) {
    //     console.log('RESULT:', dataUrl)
    //     list.unshift(dataUrl);
    //     console.log("list: ", list.length, list);
    //     await mergeImages(list).then((b64) => {
    //       // alert(allParts[i]["variable"])
    //       console.log("list b64: ", b64)
    //       // console.log(b64, allParts[i]["variable"])
    //       // allVariables[allParts[i]["variable"]] = b64;

    //       var canvas = document.getElementById("livePreview");
    //       var ctx = canvas.getContext("2d");

    //       var image = new Image();
    //       image.onload = function() {
    //         // SIZEX / 2
    //         ctx.drawImage(
    //           image,
    //           0,
    //           0,
    //           image.naturalWidth,
    //           image.naturalHeight,
    //           0,
    //           0,
    //           SIZEX/2,
    //           SIZEY/2
    //         );
    //       };
    //       image.src = b64;
    //     });
    //   }
    // )
    let predImg = new Image();
    predImg.src = finalPrediction;
    predImg.crossOrigin = "anonymous";
    predImg.onload = async function () {
      let predCanvas = document.createElement("canvas");
      predCanvas.width = SIZEX;
      predCanvas.height = SIZEY;
      let ctx2 = predCanvas.getContext("2d");
      // ctx2.drawImage(predImg, 0, 0, predImg.naturalWidth, predImg.naturalHeight, 0, 0, SIZEX, SIZEY);
      ctx2.drawImage(
        predImg,
        0,
        0,
        predImg.naturalWidth,
        predImg.naturalHeight,
        0,
        0,
        SIZEX,
        SIZEY
      );
      let data = predCanvas.toDataURL();

      // console.log("predCanvas data: ", data, predImg.naturalWidth, predImg.naturalHeight);
      list.unshift(data);

      await mergeImages(list).then((b64) => {
        // alert(allParts[i]["variable"])
        // console.log("list b64: ", b64)
        // console.log(b64, allParts[i]["variable"])
        // allVariables[allParts[i]["variable"]] = b64;

        var canvas = document.getElementById("livePreview");
        var ctx = canvas.getContext("2d");

        var image = new Image();
        image.onload = function () {
          // SIZEX / 2
          ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            SIZEX / 2,
            SIZEY / 2
          );
        };
        image.src = b64;
      });
    };
    // console.log("list: ", list.length, list)
  };

  // useEffect(() => {
  //   // livePreview();
  // }, [person_data, imageTensor, finalPrediction]);

  const savePart = (canvas, colorToSave, variable) => {
    let flag = false;
    let ctx = canvas.getContext("2d");
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, SIZEX, SIZEY);
    ctx.restore();
    // ctx.fillStyle = "rgba(0, 0, 0, 0)";
    // ctx.fillRect(0, 0, SIZEX, SIZEY);
    const picUR = canvas.toDataURL();
    // console.log(picUR, "while Saving", variable)
    if (activeIndex >= 0) {
      for (let j = 0; j < points.length; j++) {
        if (pointsColor[j].colorPreview == colorToSave) {
          // console.log(j);
          if (flag === false) {
            flag = true;
          }
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = 2;
          ctx.fillStyle = pointsColor[j].colorPreview;
          // debugger
          ctx.strokeStyle = pointsColor[j].colorPreview;
          ctx.fill();
          ctx.stroke();
        }
      }
    }
    const picURL = canvas.toDataURL();
    setSavingPart(!savingPart);
    if (flag == false) {
      return null;
    } else {
      return picURL;
    }
  };

  function make_base(ctx, src) {
    let base_image = new Image();
    base_image.src = src;
    base_image.onload = function () {
      URL.revokeObjectURL(base_image.src);
      ctx.drawImage(base_image, 0, 0, SIZEX, SIZEY);
    };
  }

  function createImage(ctx, list) {
    let newlist = [];
    for (let i = 0; i < list.length; i += 1) {
      if (list[i] !== null && list[i] !== undefined) {
        newlist.push({
          src: list[i],
          opacity: 0.1 * opacitySeg,
        });
      }
    }
    mergeImages(newlist).then(async (b64) => {
      await make_base(ctx, b64);
    });
  }

  const saveSegmentedImage = async () => {
    await setSaveInitialization(true);
    canvas = document.getElementById(canvas_name);
    const instaCanvas = canvas;
    let ctx = instaCanvas.getContext("2d");
    canvas.style.width = SIZEX + "px";
    canvas.style.height = SIZEY + "px";
    var scale = 1;
    // window.devicePixelRatio;
    canvas.width = Math.floor(SIZEX * scale);
    canvas.height = Math.floor(SIZEY * scale);
    ctx.scale(scale, scale);
    // if(props.loadImage=='segment'){
    // ctx.clearRect(0, 0, SIZEX, SIZEY);
    // ctx.fillStyle = "rgba(0, 0, 0, 0)";
    // ctx.fillRect(0, 0, SIZEX, SIZEY);

    // console.log(canvas.toDataURL())

    let allVariables = {};
    allVariables["id"] = id;
    // ****************** ALL ******************* //
    let newVariables = {};
    for (let i = 0; i < allParts.length; i += 1) {
      const pic = await savePart(
        canvas,
        allParts[i]["color"],
        allParts[i]["variable"]
      );
      // console.log(pic,allParts[i]["variable"])
      if (pic != null) {
        if (
          person_data[TABLE][0][allParts[i]["variable"]] &&
          imageToMerge[allParts[i]["variable"]] == "save"
        ) {
          let list = [];
          if (
            edgeImageToMerge[allParts[i]["variable"]] != null ||
            edgeImageToMerge[allParts[i]["variable"]] != undefined
          ) {
            list = list.concat(edgeImageToMerge[allParts[i]["variable"]]);
          }
          list.push(person_data[TABLE][0][allParts[i]["variable"]]);
          list.push(pic);
          await mergeImages(list).then((b64) => {
            // alert(allParts[i]["variable"])
            // console.log(b64, allParts[i]["variable"]);
            allVariables[allParts[i]["variable"]] = b64;
          });
        } else {
          // alert('Nope')
          let list = [];
          if (
            edgeImageToMerge[allParts[i]["variable"]] != null ||
            edgeImageToMerge[allParts[i]["variable"]] != undefined
          ) {
            // console.log(edgeImageToMerge[allParts[i]["variable"]]);
            list = list.concat(edgeImageToMerge[allParts[i]["variable"]]);
          }
          list.push(pic);
          await mergeImages(list).then((b64) => {
            // alert(allParts[i]["variable"])
            // console.log(
            //   "pic was not null but no save",
            //   b64,
            //   allParts[i]["variable"]
            // );
            allVariables[allParts[i]["variable"]] = b64;
          });
        }
      } else {
        let list = [];
        if (
          edgeImageToMerge[allParts[i]["variable"]] != null ||
          edgeImageToMerge[allParts[i]["variable"]] != undefined
        ) {
          // console.log(edgeImageToMerge[allParts[i]["variable"]]);
          list = list.concat(edgeImageToMerge[allParts[i]["variable"]]);
        }
        if (person_data[TABLE][0][allParts[i]["variable"]] != null) {
          list.push(person_data[TABLE][0][allParts[i]["variable"]]);
        }
        if (list.length > 0) {
          // console.log(list);
          await mergeImages(list).then((b64) => {
            // console.log("pic was null", b64, allParts[i]["variable"]);
            // alert(allParts[i]["variable"])
            allVariables[allParts[i]["variable"]] = b64;
          });
        } else {
          // console.log(null, allParts[i]["variable"]);
          allVariables[allParts[i]["variable"]] = null;
        }
      }
    }
    allVariables["name"] = props.name;
    // console.log(props, "name");
    allVariables["seconds_spent"] = moment().diff(initTime, "seconds");
    // console.log(moment().diff(initTime, "seconds"));
    setMutateType("saved");
    allVariables["prediction_type"] = props.loadImage;
    // console.log(topBottom, "final sent");
    // debugger;
    allVariables = {
      ...allVariables,
      ...topBottom,
    };
    // console.log(allVariables, "final sent");
    // allVariables["bad_densepose"] = badDensepose;
    // allVariables["hands_occluded"] = showOneSeg;
    Object.keys(imageTensor).forEach((key => {
      // alert(key)
      if (key !== "background") {
        let finalImageCanvas = document.getElementById(`${key}_present`)
        let ctx = finalImageCanvas.getContext("2d");
        allVariables[`${key}_seg`] = finalImageCanvas.toDataURL()
      }
      // console.log(finalImageCanvas.toDataURL())
    }))
    Object.keys(allVariables).forEach((key) => {
      if (key.includes("_only")) {
        delete allVariables[key]
      }
    })
    await save_all({
      variables: allVariables,
    });

    // let newDummyImg = new Image();
    // newDummyImg.src = image;
    // newDummyImg.crossOrigin = "anonymous";
    // newDummyImg.onload = async () => {
    //   URL.revokeObjectURL(newDummyImg.src);
    //   canvas = document.getElementById(canvas_name);
    //   // const instaCanvas = canvas;
    //   let ctx = canvas.getContext("2d");
    //   ctx.drawImage(
    //     newDummyImg,
    //     0,
    //     0,
    //     newDummyImg.naturalWidth,
    //     newDummyImg.naturalHeight,
    //     0,
    //     0,
    //     SIZEX,
    //     SIZEY
    //   );
    //   if (activeIndex >= 0) {
    //     for (let j = 0; j < points.length; j++) {
    //       ctx.beginPath();
    //       if (points[j].length > 0) {
    //         ctx.moveTo(points[j][0].x, points[j][0].y);
    //       }
    //       for (let i = 1; i < points[j].length; i++) {
    //         // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
    //         ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
    //       }
    //       ctx.lineWidth = 2;
    //       ctx.fillStyle = pointsColor[j].colorPreview;
    //       // debugger
    //       ctx.strokeStyle = pointsColor[j].colorPreview;
    //       ctx.fill();
    //       ctx.stroke();
    //     }
    //   }
    //   const imageData = ctx.getImageData(0, 0, 600, 800);
    //   wtb(imageData.data);
    //   ctx.putImageData(imageData, 0, 0);
    // };

    // ctx.fillStyle = "black";
    // ctx.fillRect(0, 0, SIZEX, SIZEY);
    // if (activeIndex >= 0) {
    //   for (let j = 0; j < points.length; j++) {
    //     ctx.beginPath();
    //     if (points[j].length > 0) {
    //       ctx.moveTo(points[j][0].x, points[j][0].y);
    //     }
    //     for (let i = 1; i < points[j].length; i++) {
    //       ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
    //     }
    //     ctx.lineWidth = 2;
    //     ctx.fillStyle = pointsColor[j].colorSave;
    //     // debugger
    //     ctx.strokeStyle = pointsColor[j].colorSave;
    //     ctx.fill();
    //     ctx.stroke();
    //   }
    // }
  };

  const previewSegmentedImage = async () => {
    canvas = document.getElementById("preview_canvas");
    const instaCanvas = canvas;
    let ctx = instaCanvas.getContext("2d");
    canvas.style.width = SIZEX + "px";
    canvas.style.height = SIZEY + "px";
    var scale = 1;
    // window.devicePixelRatio;
    canvas.width = Math.floor(SIZEX * scale);
    canvas.height = Math.floor(SIZEY * scale);
    let list = [];
    Object.keys(allParts).forEach((key) => {
      // if (imageToMerge[key["variable"]] != "scratch" && person_data[TABLE][0][key["variable"]]!=null) {
      //   list.push(person_data[TABLE][0][key["variable"]]);
      // }
      // console.log(key)
      // console.log(imageToMerge)
      if (
        imageToMerge[allParts[key]["variable"]] &&
        imageToMerge[allParts[key]["variable"]] != "scratch"
      ) {
        // alert(imageToMerge[allParts[key]["variable"]])
        if (person_data[TABLE][0][allParts[key]["variable"]]) {
          list.push(person_data[TABLE][0][allParts[key]["variable"]]);
        }
      } else if (
        imageToMerge[allParts[key]["variable"]] &&
        imageToMerge[allParts[key]["variable"]] != "scratch" &&
        person_data[TABLE][0][allParts[key]["variable"]] !== null
      ) {
        list.push(person_data[TABLE][0][allParts[key]["variable"]]);
      } else {
        if (
          !imageToMerge[allParts[key]["variable"]] &&
          person_data[TABLE][0][allParts[key]["variable"]] != null
        ) {
          list.push(person_data[TABLE][0][allParts[key]["variable"]]);
        }
      }
    });

    // Object.keys(edgeImageToMerge).forEach((key) => {
    //   if (edgeImageToMerge[key]) {
    //     list = list.concat(edgeImageToMerge[key]);
    //   }
    // });

    ctx.scale(scale, scale);
    if (props.loadImage != "segment") {
      let newDummyImg = new Image();
      newDummyImg.src = finalPrediction;
      newDummyImg.crossOrigin = "anonymous";
      newDummyImg.onload = () => {
        URL.revokeObjectURL(newDummyImg.src);
        // list.push(newDummyImg.src)
        // let src = mergeImages(list)
        const instaCanvas = document.getElementById("preview_canvas");
        //  = canvas;
        let ctx = instaCanvas.getContext("2d");
        // let adimg = new Image()
        // adimg.src = src
        // adimg.crossOrigin = "anonymous"
        ctx.drawImage(
          newDummyImg,
          0,
          0,
          newDummyImg.naturalWidth,
          newDummyImg.naturalHeight,
          0,
          0,
          SIZEX,
          SIZEY
        );
        // ctx.drawImage(
        //   adimg,
        //   0,
        //   0,
        //   SIZEX,
        //   SIZEY
        // )
        if (activeIndex >= 0) {
          for (let j = 0; j < points.length - 1; j++) {
            ctx.beginPath();
            if (points[j].length > 0) {
              ctx.moveTo(points[j][0].x, points[j][0].y);
            }
            for (let i = 1; i < points[j].length; i++) {
              // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
              ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
            }
            ctx.lineWidth = 2;
            ctx.fillStyle = pointsColor[j].colorPreview;
            // debugger
            ctx.strokeStyle = pointsColor[j].colorPreview;
            ctx.fill();
            ctx.stroke();
          }
        }
        const imageData = ctx.getImageData(0, 0, 600, 800);
        wtb(imageData.data);
        ctx.putImageData(imageData, 0, 0);
        const picURL = canvas.toDataURL();
        // list.push(picURL);
        list.unshift(picURL);
        let finalImage = new Image();
        finalImage.crossOrigin = "anonymous";
        mergeImages(list).then((b64) => {
          finalImage.src = b64;
          ctx.drawImage(finalImage, 0, 0, SIZEX, SIZEY);
          setDataUrl(b64);
        });

        LEFT_POSITION = "2200px";
        setPreview(true);
      };
    }
    // else {
    //   if (activeIndex >= 0) {
    //     for (let j = 0; j < points.length; j++) {
    //       ctx.beginPath();
    //       if (points[j].length > 0) {
    //         ctx.moveTo(points[j][0].x, points[j][0].y);
    //       }
    //       for (let i = 1; i < points[j].length; i++) {
    //         // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
    //         ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
    //       }
    //       ctx.lineWidth = 2;
    //       ctx.fillStyle = pointsColor[j].colorPreview;
    //       // debugger
    //       ctx.strokeStyle = pointsColor[j].colorPreview;
    //       ctx.fill();
    //       ctx.stroke();
    //     }
    //   }
    //   const imageData = ctx.getImageData(0, 0, 600, 800);
    //   wtb(imageData.data);
    //   ctx.putImageData(imageData, 0, 0);
    //   const picURL = canvas.toDataURL();
    //   setDataUrl(picURL);
    //   console.log(picURL);
    //   LEFT_POSITION = "1800px";
    //   setPreview(true);
    // }
  };

  const checkConnection = () => {
    // console.log(points[activeIndex], activeIndex, points[activeIndex].length, showSave)
    // if (points[activeIndex - 1].length > 2 || showSave) {
    //   // console.log(points[activeIndex -1], activeIndex, 'here')
    //   if (
    //     points[activeIndex - 1][0] == lastPoint ||
    //     points[activeIndex - 1][0] ==
    //       points[activeIndex - 1][points[activeIndex - 1].length - 1] ||
    //     showSave
    //   ) {
    //     return (
    //       <Button className="" onClick={saveSegmentedImage}>
    //         Save
    //       </Button>
    //     );
    //   }
    // }
    if (edgeImageToMerge.length > 0 || imageTensorFull()) {
      return (
        <Button className="" onClick={saveSegmentedImage}>
          Save
        </Button>
      );
    }
  };

  const fillRest = async () => {
    // console.log(predictionData)
    if (predictionData) {
      let restCan = document.createElement("canvas");
      let ctx = restCan.getContext("2d");
      // console.log(points)
      // console.log(points.length)
      restCan.style.width = SIZEX + "px";
      restCan.style.height = SIZEY + "px";
      // var scale = 1;
      // window.devicePixelRatio;
      restCan.width = Math.floor(SIZEX * 1);
      restCan.height = Math.floor(SIZEY * 1);
      if (activeIndex >= 0) {
        for (let j = points.length - 2; j < points.length - 1; j++) {
          // console.log(j)
          // console.log(points[j])
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = 2;
          ctx.fillStyle = pointsColor[j].colorPreview;
          // debugger
          ctx.strokeStyle = pointsColor[j].colorPreview;
          ctx.fill();
          ctx.stroke();
          ctx.save();
        }
      }
      ctx.restore();
      // console.log(restCan.toDataURL())
      let data = ctx.getImageData(0, 0, 600, 800);
      let img_ar = data.data;
      let f_ar = [];
      let count = 1;
      for (let i = 0; i < data.data.length; i += 4) {
        if (
          data.data[i] < 30 &&
          data.data[i + 1] < 30 &&
          data.data[i + 2] < 20
        ) {
          // console.log("yup")
          count += 1;
          data.data[i] = 0;
          data.data[i + 1] = 0;
          data.data[i + 2] = 0;
          data.data[i + 3] = 0;
        } else {
          if (
            predictionData[i] == 0 &&
            predictionData[i + 1] == 0 &&
            predictionData[i + 2] == 0
            // !(predictionData[i] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).r &&
            // predictionData[i+1] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).b &&
            // predictionData[i+2] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).g )
          ) {
          } else {
            data.data[i] = 0;
            data.data[i + 1] = 0;
            data.data[i + 2] = 0;
            data.data[i + 3] = 0;
          }
        }
      }
      ctx.putImageData(data, 0, 0);
      // alert('Reached here')
      // console.log(points[2], 'before')
      const pic = restCan.toDataURL();
      const initArr = edgeImageToMerge;
      // console.log(pic)
      // alert(pic)
      if (!initArr[activeVariable]) initArr[activeVariable] = [pic];
      else initArr[activeVariable].push(pic);

      // console.log(initArr, 'comes here')
      // alert(initArr[activeVariable])
      setEdgeImageToMerge(initArr);

      const temp = points;
      // console.log(points, 'before')
      let firstPop = temp.pop();
      let second = temp.pop();
      // console.log("Pops", firstPop, second);
      temp.push([[]]);
      // console.log(temp.length, 'all_points')
      const tempColor = pointsColor;
      let color = tempColor.pop();

      // tempColor.push({
      //   colorSave: save,
      //   colorView: view,
      //   colorPreview: preview,
      // });
      // setActiveIndex(activeIndex + 1);
      // setActiveIndex(activeIndex)
      // setToggle(false);
      // console.log(po)
      console.log(
        pointsColor[pointsColor.length - 1],
        pointsColor.length,
        temp.length,
        "final_save"
      );
      // alert(pointsColor[pointsColor.length])
      setPenultimatePoint({});
      setLastPoint({});
      setPointsColor(tempColor);
      setPoints(temp);
      setToggle(false);
      setShowSave(true);
      setActiveIndex(activeIndex - 1);
      // previewSegmentedImage();
      // setUpdate(!update)
      // setLastPoint({})
    }
  };

  const findIntersection = (ignorePart) => {
    // ignorePart = "rgba(255,85,0, 255)"
    let colors = ignorePart.substring(5);
    colors = colors.split(",");
    // console.log(colors)
    // alert(colors)
    // return
    // console.log(predictionData)
    if (predictionData) {
      // alert("prediction")
      console.log("enters find intersection")
      let restCan = document.createElement("canvas");
      let ctx = restCan.getContext("2d");
      // console.log(points)
      // console.log(points.length)
      restCan.style.width = SIZEX + "px";
      restCan.style.height = SIZEY + "px";
      // var scale = 1;
      // window.devicePixelRatio;
      restCan.width = Math.floor(SIZEX * 1);
      restCan.height = Math.floor(SIZEY * 1);
      if (activeIndex >= 0) {
        for (let j = points.length - 2; j < points.length - 1; j++) {
          // console.log(j)
          // console.log(points[j])
          // alert("Ok")
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = 2;
          ctx.fillStyle = pointsColor[j].colorPreview;
          // debugger
          ctx.strokeStyle = pointsColor[j].colorPreview;
          ctx.fill();
          ctx.stroke();
          ctx.save();
        }
      }
      ctx.restore();
      // console.log(restCan.toDataURL())
      let data = ctx.getImageData(0, 0, 600, 800);
      let img_ar = data.data;
      let f_ar = [];
      let count = 1;
      for (let i = 0; i < data.data.length; i += 4) {
        if (
          data.data[i] < 30 &&
          data.data[i + 1] < 30 &&
          data.data[i + 2] < 20
        ) {
          // console.log("yup")
          count += 1;
          data.data[i] = 0;
          data.data[i + 1] = 0;
          data.data[i + 2] = 0;
          data.data[i + 3] = 0;
        } else {
          if (
            predictionData[i] == parseInt(colors[0].trim()) &&
            predictionData[i + 1] == parseInt(colors[1].trim()) &&
            predictionData[i + 2] == parseInt(colors[2].trim())
            // !(predictionData[i] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).r &&
            // predictionData[i+1] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).b &&
            // predictionData[i+2] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).g )
          ) {
          } else {
            if (
              predictionData[i] == 0 &&
              predictionData[i + 1] == 0 &&
              predictionData[i + 2] == 0
            ) {
              if (toggleIgnoreBg == "yes") {
                data.data[i] = 0;
                data.data[i + 1] = 0;
                data.data[i + 2] = 0;
                data.data[i + 3] = 0;
              }
            } else {
              // if (ignorePart == "rgba(255,85,0, 255)") {
              // data.data[i] =
              //   (Math.round(parseInt(colors[0].trim()) + predictionData[i]) *
              //     1.3) %
              //   255;
              // data.data[i + 1] =
              //   (Math.round(
              //     parseInt(colors[1].trim()) + predictionData[i + 1]
              //   ) *
              //     1.3) %
              //   255;
              // data.data[i + 2] =
              //   (Math.round(
              //     parseInt(colors[2].trim()) + predictionData[i + 2]
              //   ) *
              //     1.3) %
              //   255;
              // data.data[i + 3] = Math.round(0.1 * opacitySeg * 255);
              // }
            }
          }
        }
      }
      ctx.putImageData(data, 0, 0);
      // console.log(points[2], 'before')
      const pic = restCan.toDataURL();
      const initArr = edgeImageToMerge;
      // alert(pic)
      if (!initArr[activeVariable]) initArr[activeVariable] = [pic];
      else initArr[activeVariable].push(pic);

      // console.log(initArr, 'comes here')
      // alert(initArr[activeVariable])
      setEdgeImageToMerge(initArr);

      const temp = points;
      // console.log(points, 'before')
      let firstPop = temp.pop();
      let second = temp.pop();
      // console.log("Pops", firstPop, second);
      temp.push([[]]);
      // console.log(temp.length, 'all_points')
      const tempColor = pointsColor;
      let color = tempColor.pop();

      // tempColor.push({
      //   colorSave: save,
      //   colorView: view,
      //   colorPreview: preview,
      // });
      // setActiveIndex(activeIndex + 1);
      // setActiveIndex(activeIndex)
      // setToggle(false);
      // console.log(po)
      // console.log(
      //   pointsColor[pointsColor.length - 1],
      //   pointsColor.length,
      //   temp.length,
      //   "final_save"
      // );
      // alert(pointsColor[pointsColor.length])
      setPenultimatePoint({});
      setLastPoint({});
      setPointsColor(tempColor);
      setPoints(temp);
      setToggle(false);
      setShowSave(true);
      setActiveIndex(activeIndex - 1);
    }
  };
  const newFillRest = async (ignorePart) => {
    // alert(ignorePart)
    // alert(ignorePart.substring(5,))
    let colors = ignorePart.substring(5);
    colors = colors.split(",");
    // console.log(colors)
    // alert(colors)
    // return
    // console.log(predictionData)
    if (predictionData) {
      let restCan = document.createElement("canvas");
      let ctx = restCan.getContext("2d");
      // console.log(points)
      // console.log(points.length)
      restCan.style.width = SIZEX + "px";
      restCan.style.height = SIZEY + "px";
      // var scale = 1;
      // window.devicePixelRatio;
      restCan.width = Math.floor(SIZEX * 1);
      restCan.height = Math.floor(SIZEY * 1);
      if (activeIndex >= 0) {
        for (let j = points.length - 2; j < points.length - 1; j++) {
          // console.log(j)
          // console.log(points[j])
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = 2;
          ctx.fillStyle = pointsColor[j].colorPreview;
          // debugger
          ctx.strokeStyle = pointsColor[j].colorPreview;
          ctx.fill();
          ctx.stroke();
          ctx.save();
        }
      }
      ctx.restore();
      // console.log(restCan.toDataURL())
      let data = ctx.getImageData(0, 0, 600, 800);
      let img_ar = data.data;
      let f_ar = [];
      let count = 1;
      for (let i = 0; i < data.data.length; i += 4) {
        if (
          data.data[i] < 30 &&
          data.data[i + 1] < 30 &&
          data.data[i + 2] < 20
        ) {
          // console.log("yup")
          count += 1;
          data.data[i] = 0;
          data.data[i + 1] = 0;
          data.data[i + 2] = 0;
          data.data[i + 3] = 0;
        } else {
          if (
            !(
              predictionData[i] == parseInt(colors[0].trim()) &&
              predictionData[i + 1] == parseInt(colors[1].trim()) &&
              predictionData[i + 2] == parseInt(colors[2].trim())
            )
            // !(predictionData[i] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).r &&
            // predictionData[i+1] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).b &&
            // predictionData[i+2] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).g )
          ) {
          } else {
            data.data[i] = 0;
            data.data[i + 1] = 0;
            data.data[i + 2] = 0;
            data.data[i + 3] = 0;
          }
        }
      }
      ctx.putImageData(data, 0, 0);
      // alert('Reached here')
      // console.log(points[2], 'before')
      const pic = restCan.toDataURL();
      const initArr = edgeImageToMerge;
      // console.log(pic)
      // alert(pic)
      if (!initArr[activeVariable]) initArr[activeVariable] = [pic];
      else initArr[activeVariable].push(pic);

      // console.log(initArr, 'comes here')
      // alert(initArr[activeVariable])
      setEdgeImageToMerge(initArr);

      const temp = points;
      // console.log(points, 'before')
      let firstPop = temp.pop();
      let second = temp.pop();
      // console.log("Pops", firstPop, second);
      temp.push([[]]);
      // console.log(temp.length, 'all_points')
      const tempColor = pointsColor;
      let color = tempColor.pop();

      // tempColor.push({
      //   colorSave: save,
      //   colorView: view,
      //   colorPreview: preview,
      // });
      // setActiveIndex(activeIndex + 1);
      // setActiveIndex(activeIndex)
      // setToggle(false);
      // console.log(po)
      console.log(
        pointsColor[pointsColor.length - 1],
        pointsColor.length,
        temp.length,
        "final_save"
      );
      // alert(pointsColor[pointsColor.length])
      setPenultimatePoint({});
      setLastPoint({});
      setPointsColor(tempColor);
      setPoints(temp);
      setToggle(false);
      setShowSave(true);
      setActiveIndex(activeIndex - 1);
      // previewSegmentedImage();
      // setUpdate(!update)
      // setLastPoint({})
    }
  };

  const checkConnectionAndFillWithRest = () => {
    if (points[activeIndex - 1].length > 2)
      if (points[activeIndex - 1][0] == lastPoint || showSave) {
        return (
          <Button className="" onClick={fillRest}>
            <div style={{ width: "130px", whiteSpace: "break-spaces" }}>
              FILL BACKGROUND
            </div>
          </Button>
        );
      }
    // if(showSave){
    //   return (
    //     <Button className="" onClick={fillRest}>
    //       <div style={{width: '130px',whiteSpace: 'break-spaces' }}>
    //         IGNORE BACKGROUND
    //       </div>
    //     </Button>
    //   );
    // }
  };

  useEffect(() => {
    // fillRest()
    console.log(ignorePart, "All images to merge new ------");
    if (ignorePart != "") {
      // newFillRest(ignorePart);
      // alert(ignorePart)
      findIntersection(ignorePart);
      // console.log(edgeImageToMerge, "all images to merge");
      createFinalPrediction(
        activeVariable,
        edgeImageToMerge,
        imageTensor,
        setImageTensor,
        setOverlappedSeg,
        person_data[TABLE][0],
        beforeBg
      );
      setIgnorePart("");
    }
  }, [ignorePart]);

  useEffect(() => {
    // alert("ok")
    console.log("Edge images to merge ------------");
    // console.log(edgeImageToMerge);
    allParts.map((part) => {
      if (edgeImageToMerge[part.variable]) {
        createFinalPrediction(
          part.variable,
          edgeImageToMerge,
          imageTensor,
          setImageTensor,
          setOverlappedSeg,
          person_data[TABLE][0],
          beforeBg
        );
      }
    });
  }, [edgeImageToMerge]);

  const checkConnectionAndFillWithSome = () => {
    if (points[activeIndex - 1].length > 2)
      if (points[activeIndex - 1][0] == lastPoint || showSave) {
        return (
          // <Button className="" onClick={fillRest}>
          <div>
            <div style={{ width: "130px", whiteSpace: "break-spaces" }}>
              IGNORE
            </div>
            <div>
              <Select
                value={ignorePart}
                // onChange={(e) => setNaReason(e.target.value)}
                onChange={(e) => setIgnorePart(e.target.value)}
              // name={naReason}
              >
                <option value={""}>None</option>
                {allParts.map((op) => {
                  if (op["variable"] != activeVariable) {
                    if (op["variable"] !== "background") {
                      return (
                        <option
                          style={{ backgroundColor: op["color"] }}
                          value={op["color"]}
                        >
                          {op["name"]}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          style={{
                            backgroundColor: "rgba(0,0,0,255)",
                            color: "white",
                          }}
                          value={"rgba(0,0,0,255)"}
                        >
                          {op["name"]}
                        </option>
                      );
                    }
                  }
                  return null;
                })}
              </Select>
            </div>
          </div>
          // </Button>
        );
      }
    // if(showSave){
    //   return (
    //     <Button className="" onClick={fillRest}>
    //       <div style={{width: '130px',whiteSpace: 'break-spaces' }}>
    //         IGNORE BACKGROUND
    //       </div>
    //     </Button>
    //   );
    // }
  };

  const deleteSeg = async () => {
    // alert(activeVariable)
    // await setSavingDelete(true)
    let allVariables = {};
    allVariables["id"] = id;
    allParts.map((part) => {
      allVariables[part["variable"]] = person_data[TABLE][0][part["variable"]];
    });
    allVariables["name"] = props.name;
    // allVariables["last_updated"] = moment().utcOffset(+330);
    allVariables[activeVariable] = null;
    allVariables["seconds_spent"] = moment().diff(initTime, "seconds");
    setMutateType("deleted");
    allVariables["prediction_type"] = person_data[TABLE][0]["prediction_type"];
    allVariables = {
      ...allVariables,
      ...topBottom,
    };
    // allVariables["bad_densepose"] = badDensepose;
    // allVariables["hands_occluded"] = showOneSeg;
    await save_all({
      variables: allVariables,
    });
    window.location.reload();
    // await deleteSegmentation({
    //   seg_var: activeVariable,
    //   id: id,
    //   name: props.name,
    //   last_updated: moment().utcOffset(+330)
    // })
  };

  const checkConnectionAndPreview = () => {
    // alert(lastPoint)
    // if (points[activeIndex - 1].length > 2 || showSave)
    //   if (points[activeIndex - 1][0] == lastPoint|| showSave) {
    //     return (
    //       <Button className="margin--10px" onClick={previewSegmentedImage}>
    //         Preview
    //       </Button>
    //     );
    //   }
    // if(activeIndex==0 && edgeImageToMerge[activeVariable] && edgeImageToMerge[activeVariable].length>0){
    //   return (
    //     <Button className="margin--10px" onClick={previewSegmentedImage}>
    //       Preview
    //     </Button>
    //   );
    // }
  };

  const [showUseLabel, setShowUseLabel] = useState(false);

  const addAnotherPath = (
    save,
    view,
    preview,
    part = null,
    variable = null
  ) => {
    setShowSave(false);
    setShowUseLabel(true);
    if (variable == "background_seg") {
      if (activeVariable !== "background_seg") {
        setBeforeBg(activeVariable)
      }
    }
    setActiveVariable(variable);
    if (person_data && person_data[TABLE][0][variable]) {
      setImageToMerge({ ...imageToMerge, [variable]: "save" });
    } else {
      setImageToMerge({ ...imageToMerge, [variable]: "scratch" });
    }
    const temp = points;
    temp.push([]);
    const tempColor = pointsColor;
    tempColor.push({
      colorSave: save,
      colorView: view,
      colorPreview: preview,
    });
    setActiveIndex(activeIndex + 1);
    setPenultimatePoint({});
    setLastPoint({});
    setPointsColor(tempColor);
    setPoints(temp);
    setToggle(false);
  };

  const markAsNA = async () => {
    if (!naReason) {
      alert("Please mention the reason for marking the image as NA");
      return;
    }
    if (person_data) {
      // console.log("allParts: ", allParts)
      let flag = false;
      allParts.map((part) => {
        if (person_data[TABLE][0][part.variable] != null) {
          flag = true;
        }
      });
      if (naReason === "No") {
        await saveAppropriate({
          variables: {
            id: id,
            is_na: "No",
            reason_for_na: naReason,
            isLabelled: flag ? "Labelled" : "Not Labelled",
          },
        });
      } else {
        await saveAppropriate({
          variables: {
            id: id,
            is_na: "Yes",
            reason_for_na: naReason,
            isLabelled: "Labelled",
          },
        });
        // nextImage();
      }
    }
  };

  const checkLabelled = () => {
    let flag = "Not Labelled";
    allParts.some((part) => {
      if (person_data[TABLE][0][part.variable] !== null) {
        return "Labelled";
        // flag = "Labelled"
        // break
      }
    });
    return "Not Labelled";
  };
  const markAsGood = async () => {
    await savePredGood({
      variables: {
        id: id,
        is_pred_good: "Yes",
        // person_data && person_data[TABLE][0]["is_pred_good"] == "Yes"
        //   ? "No"
        //   : "Yes",
        isLabelled: "Labelled",
        // hands_occluded: showOneSeg,
        // bad_densepose: badDensepose
      },
    });
    if (person_data[TABLE][0]["is_pred_good"] == "No") {
      disposeAll()
      nextImage();
    }
  };

  const showRadioButton = () => {
    if (person_data && person_data[TABLE][0][activeVariable]) {
      return (
        <div style={{ backgroundColor: "#da9c40", display: "flex" }}>
          {/* <div>
            <RadioGroup
              value={imageToMerge[activeVariable]}
              onChange={(e) => {
                if (
                  e.target.value == "save" &&
                  !person_data[TABLE][0][activeVariable]
                ) {
                  alert("No previous label is save for this class");
                  return;
                }
                setImageToMerge({
                  ...imageToMerge,
                  [activeVariable]: e.target.value,
                });
                // if(e.target.value == "save"){
                //   setListMergedImages([...listMergedImages, person_data[TABLE][0][activeVariable]])
                // }
              }}
            >
              <Radio value={"save"}>Use Saved Label</Radio>
              <Radio value={"scratch"}>Start from Scratch</Radio>
            </RadioGroup>
          </div> */}
          {/* </div> */}
          <div>
            <Button onClick={() => deleteSeg()}>Delete segmentation</Button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const valueForRadio = (part) => {
    // console.log(topBottom[part.variable.split("_seg")[0] + "_orientation"], "value", part.variable.split("_seg")[0] + "_orientation" );
    return topBottom[part.variable.split("_seg")[0] + "_orientation"];
  };

  const renderButttons = (part) => {
    return (<div style={{ ...part["main_class"] }}>
      <Button
        onClick={() =>
          addAnotherPath(
            part["color"],
            part["color"],
            part["color"],
            person_data[TABLE][0][part["variable"]],
            part["variable"]
          )
        }
        style={{
          margin: "3px 0px 0px 0px",
          backgroundColor: part["color"],
          border:
            activeVariable == part["variable"]
              ? "5px solid black"
              : "",
          ...part["classes"],
        }}
      // className={part["classes"]}
      >
        <div>
          {part["name"]}
          {part["extra_info"] ? (
            <div>{part["extra_info"]}</div>
          ) : null}
        </div>
      </Button>
      {false && part["radio"] && (
        //     <RadioGroup
        //       onChange={(e) => {
        //         console.log(e)
        //         let tempTopBottom = topBottom
        //         tempTopBottom[part.variable.split("_seg")[0] + "_orientation"] = e.target.value
        //         console.log(tempTopBottom)
        //         setTopBottom(tempTopBottom)
        //         setRadioChange(!radioChange)

        //       }}
        //       value={`${() => {return valueForRadio(part)}}`}
        //       style={{ display: "flex", padding: "0px 5px", backgroundColor:  part["color"], opacity: 0.8 }}
        //     >
        //     <Radio value={"top"} style={{ margin: "0px 5px" }}>
        //       Top
        //   </Radio>
        //   <Radio value={"bottom"} style={{ margin: "0px 5px" }}>
        //   Bottom
        // </Radio>
        // </RadioGroup>
        <div
          style={{
            display: "flex",
            padding: "0px 5px",
            backgroundColor: part["color"],
            opacity: 0.8,
          }}
        >
          <div>
            <input
              type="radio"
              checked={
                valueForRadio(part) == "top" ? "checked" : ""
              }
              name={part["variable"]}
              value={"top"}
              style={{ margin: "0px 5px" }}
              onClick={(e) => {
                // console.log(e)
                let tempTopBottom = topBottom;
                tempTopBottom[
                  part.variable.split("_seg")[0] +
                  "_orientation"
                ] = e.target.value;
                // console.log(tempTopBottom)
                setTopBottom(tempTopBottom);
                setRadioChange(!radioChange);
              }}
              style={{ cursor: "pointer" }}
            />
            Top
          </div>
          {/* </input> */}
          <div>
            <input
              type="radio"
              checked={
                valueForRadio(part) == "bottom"
                  ? "checked"
                  : ""
              }
              name={part["variable"]}
              value={"bottom"}
              style={{ margin: "0px 5px" }}
              onClick={(e) => {
                let tempTopBottom = topBottom;
                tempTopBottom[
                  part.variable.split("_seg")[0] +
                  "_orientation"
                ] = e.target.value;
                setTopBottom(tempTopBottom);
                setRadioChange(!radioChange);
              }}
              style={{ cursor: "pointer" }}
            />
            Bottom
          </div>
        </div>
      )}
      {/* </RadioGroup>} */}
      {part["desc"] && (
        <div>
          <b>{part["desc"]}</b>
        </div>
      )}
    </div>)
  }

  function makeTopsPersonLink() {
    if (person_data[TABLE][0]['map_new_cloth'] && person_data[TABLE][0]['map_new_cloth']["map_tops_person_cloth"] && person_data[TABLE][0]['map_new_cloth']["map_tops_cloth_tool"]["id"]) {
      return `http://34.68.244.109:8001/name=sid/imageid=${person_data[TABLE][0]['map_new_cloth']["map_tops_cloth_tool"]["id"]}?showPerson=false`
    }
    if (person_data[TABLE][0]['map_cloth'] && person_data[TABLE][0]['map_cloth']["map_tops_cloth_tool"] && person_data[TABLE][0]['map_cloth']["map_tops_cloth_tool"]["id"]) {
      return `http://34.68.244.109:8001/name=sid/imageid=${person_data[TABLE][0]['map_cloth']["map_tops_cloth_tool"]["id"]}?showPerson=false`
    }

  }

  const addModSetColor = () => {
    setModValue((parseInt(modValue) + 1) > 8? 0 :  (parseInt(modValue) + 1))
  }
  const minusModSetColor = () => {
    setModValue((parseInt(modValue) - 1) < -8? 0 :  (parseInt(modValue) - 1))
  }

  useEffect(() => {
    var x = localStorage.getItem("modValue") || 0;
    setModValue(parseInt(x))
  }, [])

  useEffect(() => {
    setRefreshFull(true)
    if(window){
      localStorage.setItem("modValue", modValue.toString());
    }
  }, [modValue])
  useEffect(() => {
    // console.log(finalPrediction)
    // alert("pre")
    let pred = new Image();
    pred.src = finalPrediction;
    pred.crossOrigin = "anonymous";
    // pred.style.imageRendering = "pixelated"
    pred.onload = async () => {
      // URL.revokeObjectURL(pred.src);
      // console.log(pred.src)
      const imageCan = document.createElement("canvas");
      let ctx = imageCan.getContext("2d");
      imageCan.style.width = 600 + "px";
      imageCan.style.height = 800 + "px";
      // var scale = 1;
      // window.devicePixelRatio;
      imageCan.width = Math.floor(600 * 1);
      imageCan.height = Math.floor(800 * 1);
      // ctx.scale(scale, scale);
      ctx.drawImage(
        pred,
        0,
        0,
        pred.naturalWidth,
        pred.naturalHeight,
        0,
        0,
        pred.naturalWidth,
        pred.naturalHeight
      );
      // console.log(imageCan.toDataURL())
      const imageData = ctx.getImageData(
        0,
        0,
        pred.naturalWidth,
        pred.naturalHeight
      );

      // let arr = imageData.data
      // console.log(typeof imageData.data)
      // let ten = tf.tensor(arr)
      // let fin = ten.reshape([256, 192, 3])

      // tf.reshape()

      // console.log(ten.length)
      // console.log(imageData.data.length, 'length')
      let count = 1;
      for (let i = 0; i < imageData.data.length; i += 1) {
        if (
          imageData.data[i] < 30 &&
          imageData.data[i] < 30 &&
          imageData.data[i] < 30
        ) {
          count += 1;
        }
      }
      // console.log(count)
      setPredictionData(imageData.data);
      // if (pred.naturalWidth < 600) {
      //   // console.log('runs')
      //   let fin = tf.browser.fromPixels(imageData);
      //   // console.log(fin.shape)
      //   let ten = resize(fin, 600, 800, true);
      //   // console.log(ten.length)
      //   setPredictionData(ten);
      // }
      // console.log(predictionData)
    };
    // URL.revokeObjectURL(pred.src);
  }, [image, finalPrediction, mode]);

  const refreshFullAction = () => {
    setRefreshFull(true)
  }

  useEffect(() => {
    if(!activeVariable.includes("_overlapped") && !activeVariable.includes("_only")){
      // if(activeVariable.substring(0,3).includes("la") || activeVariable.substring(0, 3).includes("ua")){
        console.log("enters activeVariable change")
        // setRefreshFull(true)
        setChangePred(true)
      
    }
  }, [activeVariable])
  // console.log(dataUrl, "image")
  return (
    <div>
      <div
        className="display--flex"
        style={{ padding: "0px 0px 40px 10px", flexDirection: "row" }}
      >
        <div className="container">
          <canvas
            // src={finalPrediction}
            // className="frame"
            style={{ zIndex: -10 }}
            id="image"
            style={{ display: "none" }}
          ></canvas>
          <canvas
            // src={finalPrediction}
            className="frame item"
            style={{
              zIndex: -1,
              width: SIZEX,
              height: SIZEY,
              opacity: `${(opacity + 5) * 0.1}`,
              top: "0px",
              left: "0px",
            }}
            id="image1"
          // style={{display: "none"}}
          ></canvas>
          <canvas
            className="frame item"
            id={canvas_name}
            onClick={capturePoint}
            ref={canvasRef}
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              zIndex: "2",
            }}
          />
          <img
            // size="500px"
            height="800px"
            width="600px"
            className="item"
            src={
              !props.toggleImage
                ? props.url
                : `https://storage.cloud.google.com/chan_helper/ashish/relabeling/TopCloth_coloured/${props.person_id}_topImage.png`
            }
            alt="No image in database"
            id="storage_image"
            style={{
              //   position: "absolute",
              top: "0px",
              left: "0px",
              zIndex: "0",
              opacity: `${opacity * 0.1}`,
            }}
          />
          {
          //   <canvas
          //   // src={finalPrediction}
          //   className="frame item"
          //   style={{
          //     // zIndex: -1,
          //     width: SIZEX,
          //     height: SIZEY,
          //     // opacity: `${(opacity + 5) * 0.1}`,
          //     top: "0px",
          //     left: "0px",
          //   }}
          //   id="backgroundPred"
          // // style={{display: "none"}}
          // ></canvas>
}         
          {mode=="full" && (<img
            // size="500px"
            height="800px"
            width="600px"
            className="item"
            src={
              backgroundPred
            }
            alt="No image in database"
            id="storage_image"
            style={{
              //   position: "absolute",
              top: "0px",
              left: "0px",
              zIndex: "0",
              opacity: `${opacity * 0.1}`,
            }}
          />)}

          {mode == "overlapped" && overlappedSeg && <img
            // size="500px"
            height="800px"
            width="600px"
            className="item"
            src={
              // createOverlappedWork()
              overlappedSeg
            }
            alt="No image in database"
            id="storage_image"
            style={{
              //   position: "absolute",
              top: "0px",
              left: "0px",
              zIndex: "0",
              opacity: `${opacity * 0.1}`,
            }}
          />}
        </div>
        {/* <img 
          style={{position: "absolute", top: "900px"}}
          src={image}
          width={SIZEX}
          height={SIZEY}
        /> */}

        <div style={{ padding: "20px", width: "600px" }}>
          {points[activeIndex].length > 0 && (
            <div>
              <Button
                className="margin--10px"
                style={{ height: "40px" }}
                onClick={lastPointClick}
              >
                Connect to first point
              </Button>
            </div>
          )}
          {points[activeIndex].length > 0 && (
            <div>
              <Button
                className="margin--10px"
                style={{ height: "40px" }}
                onClick={deletePointClick}
              >
                Delete last point
              </Button>
            </div>
          )}
          <div>
            {PRED_GOOD_AVAILABLE && (
              <Button variantColor="blue" onClick={markAsGood}>
                {"Prediction Good"}
              </Button>
            )}
            {PRED_GOOD_AVAILABLE &&
              person_data &&
              person_data[TABLE][0]["is_pred_good"] == "Yes" && (
                <div>The prediction is marked as Good </div>
              )}
          </div>
          {(showSave) && checkConnection()}
          {(points[activeIndex].length > 0 || showSave) &&
            checkConnectionAndPreview()}
          {(points[activeIndex].length > 0 || showSave) &&
            checkConnectionAndFillWithRest()}
          <div>
            {(points[activeIndex].length > 0 || showSave) &&
              checkConnectionAndFillWithSome()}
          </div>
          {savedImg && <div>The image is {mutateType} !</div>}
          {person_data && person_data[TABLE][0]["is_na"] == "Yes" && (
            <div>The image is marked as NA</div>
          )}
          <div style={{ display: "flex" }}>
            <div>
              <div style={{ width: "600px" }}>
                <div style={{ display: "flex" }}>
                  <Button onClick={() => setOpacity(opacity + 1)}>+</Button>
                  <div style={{ margin: "0px 10px" }}>
                    <b>{opacity}</b>
                  </div>
                  <Button onClick={() => setOpacity(opacity - 1)}>-</Button>
                  <div
                    style={{ margin: "0px 5px", backgroundColor: "aliceblue" }}
                  >
                    <div>IGNORE BACKGROUND</div>
                    {
                      <RadioGroup
                        onChange={(e) => setToggleIgnoreBg(e.target.value)}
                        value={toggleIgnoreBg}
                        style={{ display: "flex", padding: "0px 5px" }}
                      >
                        <Radio value={"yes"} style={{ margin: "0px 5px" }}>
                          Yes
                        </Radio>
                        <Radio value={"no"} style={{ margin: "0px 5px" }}>
                          No
                        </Radio>
                      </RadioGroup>
                    }
                  </div>
                  <div
                    style={{ margin: "0px 5px", backgroundColor: "aliceblue" }}
                  >
                    <div>MODE</div>
                    {
                      <RadioGroup
                        onChange={(e) => setMode(e.target.value)}
                        value={mode}
                        style={{ display: "flex", padding: "0px 5px" }}
                      >
                        <Radio value={"full"} style={{ margin: "0px 5px" }}>
                          FULL
                        </Radio>
                        <Radio
                          value={"overlapped"}
                          style={{ margin: "0px 5px" }}
                        >
                          OVERLAPPED
                        </Radio>
                      </RadioGroup>
                    }
                  </div>
                  <Button
                    onClick={() => {
                      // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory(), "123.1")
                      disposeAll()
                      // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory(), "123.2")
                      // alert("Memory")
                      nextImage()
                    }}
                    style={{ margin: "0px 0px 0px 0px" }}
                  >
                    Next{" "}
                  </Button>
                </div>
                <div>{showUseLabel && showRadioButton()}</div>
              </div>
              {/* <div></div> */}
              {mode == "full" && <div style={{ width: "500px", margin: "10px 5px", border: "1px solid black", boxShadow: "2px 2px 3px 0px black" }}>
                <b>FULL MODE ON</b>
                <div>
                  Draw the full part of the body which includes the visible and occluded / obstructed part.
                </div>
              </div>}
              {mode == "overlapped" && <div style={{ width: "500px", margin: "10px 5px", border: "1px solid black", boxShadow: "2px 2px 3px 0px black" }}>
                <b>OVERLAPPED MODE ON</b>
                <div>
                  For all overlapping areas, mark which part lies on TOP.
                  <br />
                  Example if left lower hand is covering torso, mark the area having left lower hand over torso as LEFT LOWER HAND ON TOP
                </div>
              </div>}
              <div style={{ display: "flex", width: "600px" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {allParts.map((part) => {
                    if (
                      mode == "full" &&
                      (!part["variable"].includes("_overlapped") && !(part["variable"].includes("_only")))
                    ) {
                      // <div>
                      return renderButttons(part)
                      // </div>
                    }
                    if (
                      mode == "overlapped" &&
                      ((part["variable"].includes("_overlapped")) || (part["variable"].includes("_only")))
                    ) {
                      return renderButttons(part)
                    }
                  })}
                </div>
              </div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
          <div>
            {/* <div style={{ margin: "0px 5px", backgroundColor: "aliceblue" }}>
              <div>SHOW ONE SEGMENTATION</div>
              {
                <RadioGroup
                  onChange={(e) => setShowOneSeg(e.target.value)}
                  value={showOneSeg}
                  style={{ display: "flex", padding: "0px 5px" }}
                >
                  <Radio value={"Yes"} style={{ margin: "0px 5px" }}>
                    Yes
                  </Radio>
                  <Radio value={"No"} style={{ margin: "0px 5px" }}>
                    No
                  </Radio>
                </RadioGroup>
              }
            </div> */}
            {/* <div style={{ width: "370px" }}>
              <div style={{ margin: "10px 5px" }}>
                Are the  <b> hands Occluded </b>?
              </div>
            </div> */}
            {/* {INITIAL && <Select
                value={showOneSeg}
                onChange={(e) => setShowOneSeg(e.target.value)}
                name={showOneSeg}
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Select>} */}
            <div style={{ width: "370px" }}>
              {INITIAL && (
                <div style={{ margin: "10px 5px" }}>
                  Is the initial densepose <b> bad </b>?
                </div>
              )}
            </div>
            {INITIAL && (
              <Select
                value={badDensepose}
                onChange={(e) => setBadDensepose(e.target.value)}
                name={badDensepose}
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Select>
            )}
            <div style={{ width: "370px" }}>
              <div style={{ margin: "10px 5px" }}>
                Is the image <b> NA </b>? If yes, what is the <b>reason</b> for{" "}
                <b>marking image as NA</b> ?
              </div>
            </div>
            {
              <Select
                value={naReason}
                onChange={(e) => setNaReason(e.target.value)}
                name={naReason}
              >
                <option value="No">No</option>
                <option value="black_white">Black And White</option>
                <option value="back_side_pose">
                  Back/Side Pose (90&deg; and more)
                </option>
                <option value="slightly_side_pose">
                  SLightly Side Pose ( 45&deg; to less than 90&deg;)
                </option>
                <option value="weird_pose">
                  Weird Pose (Sitting Down, Weirdly Bent){" "}
                </option>
                <option value="half_cut">Half cut image of person</option>
                {/* <option value="necklace_present">
                  Body Occluded by necklace
                </option> */}
                <option value="occluded_image">
                  Body Occluded by any object, accessory
                </option>
                <option value="cloth_as_accessory">
                  Wearing Cloth as An accessory
                </option>
                <option value="multiple_people">Multiple People</option>
                <option value="no_person">No person in Image</option>
                <option value="watermark_present">
                  Watermark over the person
                </option>
                {/* {allNaReason.map((op) => {
                      if (op["variable"] != part["variable"]) {
                        return (
                          <option value={op["variable"]}>{op["name"]}</option>
                        );
                      }
                      return null;
                    })} */}
              </Select>
            }
            {
              <Button
                style={{ margin: "20px 0px 0px 0px" }}
                onClick={() => markAsNA()}
              >
                Save
              </Button>
            }
            {props.name == "admin" && person_data && (
              <a
                href={`http://34.68.244.109:3006/name=admin/imageid=${person_data[TABLE][0]["map_grapy"]["id"]}`}
                target={"_blank"}
              >
                <h4>View Grapy</h4>
              </a>
            )}
            <div>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "wheat",
                  margin: "15px 15px",
                  justifyContent: "space-around",
                  width: "250px",
                }}
              >
                <div>All Segmentation opacity: </div>
                <Button onClick={() => setOpacitySeg(opacitySeg + 1)}>+</Button>
                <div style={{ margin: "0px 10px" }}>
                  <b>{opacitySeg}</b>
                </div>
                <Button onClick={() => setOpacitySeg(opacitySeg - 1)}>-</Button>
                {/* <Button onClick={() => nextImage()}>Next </Button> */}
              </div>
            </div>
            {/* <b>ASSIGN</b> */}
            {/* <div 
              // style={{ display: "flex" }}
            >
              {allParts.map((part) => (
                <div 
                style={{ display: "flex" }}
                >
                  <div
                    style={{
                      height: "40px",
                      lineHeight: "40px",
                      width: "70%",
                      backgroundColor: part["color"],
                    }}
                  >
                    {part["name"]}
                  </div>
                  <Select value={formFields[part['variable']]} onChange={onInputChange} name={part['variable']}>
                    <option value="">None</option>
                    {allParts.map((op) => {
                      if (op["variable"] != part["variable"]) {
                        return (
                          <option value={op["variable"]}>{op["name"]}</option>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </div>
              ))}
            </div> */}
          </div>
          {/* <img
            // size="500px"
            height="800px"
            width="600px"
            src={
              !props.toggleImage
                ? `${OUTPUT_BACKGROUND}${props.person_id}${OUTPUT_BACKGROUND_EXT}`
                : `https://storage.cloud.google.com/chan_helper/ashish/relabeling/TopCloth_coloured/${props.person_id}_topImage.png`
            }
            alt="No image in database"
            id="storage_image"
            style={{
              position: "absolute",
              top: "72px",
              left: "21px",
              zIndex: "-1",
              opacity: `${opacity * 0.1}`,
            }}
          /> */}
          <div>
            {/* <img
              height="512px"
              width="384px"
              src={
                person_data &&
                person_data[TABLE][0]
                ["map_related_cloth_rm"][
                  "map_cloth"
                ]["image_url"]
              }
              alt="No image in database"
              id="storage_image"
              // style={{position: "absolute",top: "72px",left: "21px",opacity: "0.5"}}
            /> */}
          </div>
          {/* <img src={props.url} width="384" height="512" /> */}
        </div>

        <div style={{ display: "flex" }}>
          {/* <canvas
            className="frame-temp"
            id={"preview_canvas"}
            // onClick={capturePoint}
            // ref={canvasRef}
            style={{ display: "none" }}
          /> */}
          {false && dataUrl && (
            <img src={dataUrl} style={{ width: SIZEX, height: SIZEY }} />
          )}
          <div>
            <div style={{ position: "absolute", height: SIZEX / 2 }}>
              {/* <img
              // style={{position: "absolute", top: "900px"}}
              src={image}
              width={SIZEX / 2}
              height={SIZEY / 2}
            /> */}
              {/* <canvas
                className=""
                id="livePreview"
                // onClick={capturePoint}
                // ref={canvasRef}
                width={SIZEX / 2}
                height={SIZEY / 2}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  zIndex: "2",
                  display: "flex",
                }}
              /> */}
            </div>

            <div>
              {/* <br/>
            <br/> */}
              <div>
                {/* {person_data && person_data[TABLE][0].isLabelled == "Labelled" && (<b>AFTER WORK ---{'>'} </b>)} */}
              </div>
              <div style={{ paddingTop: "405px", width: "250px" }}>
                <b style={{ display: "none" }}> Prediction after work</b>
              </div>
              <div>
                <img
                  style={{
                    position: "absolute",
                    top: "500px",
                    marginLeft: "-125px",
                  }}
                  src={props.url}
                  width={SIZEX / 2}
                  height={SIZEY / 2}
                />
              </div>
            </div>
          </div>
          {person_data && person_data[TABLE][0].isLabelled == "Labelled" && (
            <div style={{ display: "none" }}>
              <div>
                <img
                  src={`${OUTPUT_BACKGROUND}${props.person_id}${OUTPUT_BACKGROUND_EXT}`}
                  width="600px"
                  height="800px"
                  style={{
                    position: "absolute",
                    left: LEFT_POSITION,
                    top: TOP_POSITION,
                    opacity: "1",
                  }}
                />
                <img
                  src={image}
                  width="600px"
                  height="800px"
                  style={{
                    position: "absolute",
                    left: LEFT_POSITION,
                    top: TOP_POSITION,
                    opacity: "0.3",
                  }}
                />
                {/* {allParts.map((part) => (
                  <div>
                    {show[part["variable"]] &&
                      person_data &&
                      person_data[TABLE][0][part["variable"]] && (
                        <img
                          src={person_data[TABLE][0][part["variable"]]}
                          width="600px"
                          height="800px"
                          style={{
                            position: "absolute",
                            left: LEFT_POSITION,
                            top: TOP_POSITION,
                            opacity: `${opacitySeg * 0.1}`,
                          }}
                        />
                      )}
                  </div>
                ))} */}
              </div>
            </div>
          )}
          {/* {person_data && person_data[TABLE][0]["isLabelled"] == "Labelled" && (
            <div
              style={{
                position: "absolute",
                top: "900px",
                left: LEFT_POSITION,
              }}
            >
              <p>SHOW</p>

              <div
                style={{ display: "flex", width: "500px", flexWrap: "wrap" }}
              >
                  <Button 
                    onClick={() => showClicked("all")}
                  >
                    SHOW ALL
                  </Button>
                {allParts.map((part) => (
                  <div>
                    {person_data && person_data[TABLE][0][part["variable"]] && (
                      <Button onClick={() => showClicked(part["variable"])}>
                        {part["name"]}
                      </Button>
                    )}
                  </div>
                ))}
                <Button onClick={() => setOpacitySeg(opacitySeg + 1)}>+</Button>
                <div style={{ margin: "0px 10px" }}>
                  <b>{opacitySeg}</b>
                </div>
                <Button onClick={() => setOpacitySeg(opacitySeg - 1)}>-</Button>
              </div>
            </div>
          )} */}
        </div>
      </div>
      <div >
        <h2>For Reference</h2>
        <div style={{ display: "flex", gridRow: "1" }} className={""}>
          <div>
            <h2>Full <button onClick={refreshFullAction}> Refresh </button> <button onClick={addModSetColor}> + </button> {modValue} <button onClick={minusModSetColor} > - </button></h2>
            <canvas
              // src={""}
              id={"full_present"}

            // width={600}
            // onLoad={(pred) => {
            //   if (
            //     pred.target.naturalWidth !== 0 &&
            //     pred.target.naturalHeight !== 0
            //   ) {
            //     predictionOnload(pred, "torso", imageTensor, setImageTensor);
            //   }
            // }}
            />
            <img src={props.url} width={"600px"} height={"800px"} style={{ position: "absolute", left: "10px", opacity: "0.3" }} />
          </div>
          <div>
            <h2>Overlap {allParts.map((part, index) => {
              if (part["variable"].includes("_overlapped") && !(part["variable"].includes("_only"))) {
                return (<Button style={{ backgroundColor: part["color"], ...part["classes"] }} onClick={() => {
                  console.log(part["variable"])
                  setShowSegReview(part["variable"])
                }}>{part["name"]}</Button>)
              }
            })}
            </h2>
            <img src={showSegReview ? person_data[TABLE][0][showSegReview]: overlappedSeg} width={"600px"} height={"800px"} style={{ position: "absolute", left: "610px", opacity: "1" }} />
            <img src={finalPrediction} width={"600px"} height={"800px"} style={{ position: "absolute", left: "610px", opacity: "0.5" }} />
            <img src={props.url} width={"600px"} height={"800px"} style={{ position: "absolute", left: "610px", opacity: "0.5" }} />

          </div>
          
        </div>
      </div>

      <div style={{ display: "none"}}>
      <div>
            <h2>Torso </h2>
            <canvas
              // src={""}
              id={"torso_present"}
              
              // width={600}
              // onLoad={(pred) => {
              //   if (
              //     pred.target.naturalWidth !== 0 &&
              //     pred.target.naturalHeight !== 0
              //   ) {
              //     predictionOnload(pred, "torso", imageTensor, setImageTensor);
              //   }
              // }}
            />
            <img src={props.url} width={"600px"} height={"800px"} style={{ position: "absolute", left: "610px", opacity: "0.5"}}/>
          </div>
          <div>
            <h2>LEFT UPPER ARM </h2>
            <canvas
              // src={""}
              id={"lua_present"}
              // width={600}
              // onLoad={(pred) => {
              //   if (
              //     pred.target.naturalWidth !== 0 &&
              //     pred.target.naturalHeight !== 0
              //   ) {
              //     predictionOnload(pred, "torso", imageTensor, setImageTensor);
              //   }
              // }}
            />
            <img src={props.url} width={"600px"} height={"800px"} style={{ position: "absolute", left: "1210px", opacity: "0.5"}}/>
          </div>
          <div>
            <h2>RIGHT UPPER ARM </h2>
            <canvas
              // src={""}
              id={"rua_present"}
              
              // width={600}
              // onLoad={(pred) => {
              //   if (
              //     pred.target.naturalWidth !== 0 &&
              //     pred.target.naturalHeight !== 0
              //   ) {
              //     predictionOnload(pred, "torso", imageTensor, setImageTensor);
              //   }
              // }}
            />
            <img src={props.url} width={"600px"} height={"800px"} style={{ position: "absolute", left: "1810px", opacity: "0.5"}}/>
          </div>
          <div>
            <h2>LEFT LOWER Arm</h2>
            <canvas
              // src={""}
              id={"lla_present"}
              
              // width={600}
              // onLoad={(pred) => {
              //   if (
              //     pred.target.naturalWidth !== 0 &&
              //     pred.target.naturalHeight !== 0
              //   ) {
              //     predictionOnload(pred, "torso", imageTensor, setImageTensor);
              //   }
              // }}
            />
            <img src={props.url} width={"600px"} height={"800px"} style={{ position: "absolute", left: "2410px", opacity: "0.5"}}/>
          </div>
          <div>
            <h2>RIGTH LOWER ARM </h2>
            <canvas
              // src={""}
              id={"rla_present"}
              
              // width={600}
              // onLoad={(pred) => {
              //   if (
              //     pred.target.naturalWidth !== 0 &&
              //     pred.target.naturalHeight !== 0
              //   ) {
              //     predictionOnload(pred, "torso", imageTensor, setImageTensor);
              //   }
              // }}
            />
            <img src={props.url} width={"600px"} height={"800px"} style={{ position: "absolute", left: "3010px", opacity: "0.5"}}/>
          </div>
          <div>
            <h2>Collar </h2>
            <canvas
              // src={""}
              id={"collar_present"}
              
              // width={600}
              // onLoad={(pred) => {
              //   if (
              //     pred.target.naturalWidth !== 0 &&
              //     pred.target.naturalHeight !== 0
              //   ) {
              //     predictionOnload(pred, "torso", imageTensor, setImageTensor);
              //   }
              // }}
            />
            <img src={props.url} width={"600px"} height={"800px"} style={{ position: "absolute", left: "3610px", opacity: "0.5"}}/>
          </div>
          <div>
            <h2>Straps </h2>
            <canvas
              // src={""}
              id={"straps_present"}
              
              // width={600}
              // onLoad={(pred) => {
              //   if (
              //     pred.target.naturalWidth !== 0 &&
              //     pred.target.naturalHeight !== 0
              //   ) {
              //     predictionOnload(pred, "torso", imageTensor, setImageTensor);
              //   }
              // }}
            />
            <img src={props.url} width={"600px"} height={"800px"} style={{ position: "absolute", left: "4210px", opacity: "0.5"}}/>
          </div>
      </div>
      {/* </div> */}
      <div style={{ display: "none" }}>
        <h2>For Reference</h2>
        <div style={{ display: "flex", gridRow: "1" }} className={"examples"}>
          <div>
            <h2>Torso </h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "torso")}
              id={"torso"}
              // width={600}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth !== 0 &&
                  pred.target.naturalHeight !== 0
                ) {
                  predictionOnload(pred, "torso", imageTensor, setImageTensor, person_data);
                }
              }}
            />
          </div>
          <div>
            <h2>Left Upper Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "lua")}
              id={"lua"}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth != 0 &&
                  pred.target.naturalHeight != 0
                ) {
                  predictionOnload(pred, "lua", imageTensor, setImageTensor, person_data);
                }
              }}
            />
          </div>
          <div>
            <h2>Right Upper Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "rua")}
              id={"rua"}
              onLoad={(pred) =>
                predictionOnload(pred, "rua", imageTensor, setImageTensor, person_data)
              }
            />
          </div>
          <div>
            <h2>Right Lower Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "rla")}
              id={"rla"}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth != 0 &&
                  pred.target.naturalHeight != 0
                ) {
                  predictionOnload(pred, "rla", imageTensor, setImageTensor, person_data);
                }
              }}
            />
          </div>
          <div>
            <h2>Left Lower Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "lla")}
              id={"lla"}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth != 0 &&
                  pred.target.naturalHeight != 0
                ) {
                  predictionOnload(pred, "lla", imageTensor, setImageTensor, person_data);
                }
              }}
            />
          </div>
          <div>
            <h2>Collar </h2>
            <img
              src={BlackImage}
              id={"collar"}
              // width={600}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth !== 0 &&
                  pred.target.naturalHeight !== 0
                ) {
                  predictionOnload(pred, "collar", imageTensor, setImageTensor, person_data);
                }
              }}
            />
          </div>
          {/* <div>
            <h2>Straps </h2>
            <img
              src={BlackImage}
              id={"straps"}
              // width={600}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth !== 0 &&
                  pred.target.naturalHeight !== 0
                ) {
                  predictionOnload(pred, "straps", imageTensor, setImageTensor);
                }
              }}
            />
          </div> */}
          <div>
            <h2>Final Image</h2>
            <canvas id={"finalImage"}></canvas>
          </div>
          <div>
            <h2>Final Image 1</h2>
            <canvas id={"finalImage1"}></canvas>
          </div>
          <div>
            <h2>Final Image 2</h2>
            <canvas id={"finalImage2"}></canvas>
          </div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <h2>For Reference</h2>
        <div>
          <h2>Torso </h2>
          <canvas
            // src={""}
            id={"torso_present"}
          // width={600}
          // onLoad={(pred) => {
          //   if (
          //     pred.target.naturalWidth !== 0 &&
          //     pred.target.naturalHeight !== 0
          //   ) {
          //     predictionOnload(pred, "torso", imageTensor, setImageTensor);
          //   }
          // }}
          />
        </div>
        {/* <div style={{ display: "flex", gridRow: "1" }} className={"examples"}>
          <div>
            <h2>Torso </h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "torso")}
              id={"torso"}
              // width={600}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth !== 0 &&
                  pred.target.naturalHeight !== 0
                ) {
                  predictionOnload(pred, "torso", imageTensor, setImageTensor);
                }
              }}
            />
          </div>
          <div>
            <h2>Left Upper Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "lua")}
              id={"lua"}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth != 0 &&
                  pred.target.naturalHeight != 0
                ) {
                  predictionOnload(pred, "lua", imageTensor, setImageTensor);
                }
              }}
            />
          </div>
          <div>
            <h2>Right Upper Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "rua")}
              id={"rua"}
              onLoad={(pred) =>
                predictionOnload(pred, "rua", imageTensor, setImageTensor)
              }
            />
          </div>
          <div>
            <h2>Right Lower Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "rla")}
              id={"rla"}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth != 0 &&
                  pred.target.naturalHeight != 0
                ) {
                  predictionOnload(pred, "rla", imageTensor, setImageTensor);
                }
              }}
            />
          </div>
          <div>
            <h2>Left Lower Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "lla")}
              id={"lla"}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth != 0 &&
                  pred.target.naturalHeight != 0
                ) {
                  predictionOnload(pred, "lla", imageTensor, setImageTensor);
                }
              }}
            />
          </div>

          <div>
            <h2>Left Lower Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "lla")}
              id={"lla"}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth != 0 &&
                  pred.target.naturalHeight != 0
                ) {
                  predictionOnload(pred, "lla", imageTensor, setImageTensor);
                }
              }}
            />
          </div>

          <div>
            <h2>Left Lower Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "lla")}
              id={"lla"}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth != 0 &&
                  pred.target.naturalHeight != 0
                ) {
                  predictionOnload(pred, "lla", imageTensor, setImageTensor);
                }
              }}
            />
          </div>

          <div>
            <h2>Left Lower Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "lla")}
              id={"lla"}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth != 0 &&
                  pred.target.naturalHeight != 0
                ) {
                  predictionOnload(pred, "lla", imageTensor, setImageTensor);
                }
              }}
            />
          </div>

          <div>
            <h2>Left Lower Arm</h2>
            <img
              src={linkChange_id(person_data[TABLE][0]["person_id"], "lla")}
              id={"lla"}
              onLoad={(pred) => {
                if (
                  pred.target.naturalWidth != 0 &&
                  pred.target.naturalHeight != 0
                ) {
                  predictionOnload(pred, "lla", imageTensor, setImageTensor);
                }
              }}
            />
          </div> */}
        {/* <div>
            <h2>Final Image</h2>
            <canvas id={"finalImage"}></canvas>
          </div> */}
        {/* <div>
            <h2>Final Image 1</h2>
            <canvas id={"finalImage1"}></canvas>
          </div> */}
        {/* <div>
            <h2>Final Image 2</h2>
            <canvas id={"finalImage2"}></canvas>
          </div> */}
        {/* </div> */}
      </div>

      <div className="display--flex">
        {false && <iframe src={makeTopsPersonLink()} width="2000" height="2000" />}
        {/* <div className={"container"}>
          
          <img width="600" height="800" src={makePersonLink()} className={"item"}/>
          <img width="600" height="800" src={makePersonLink()} className={"item"}/>
        </div> */}
      </div>
    </div>
  );

  // })

  // console.log(tf.memory()["numBytes"],tf.memory()["numTensors"],tf.memory())
};

export default MapPoints;

{
  /* <div style={{ display: "flex", padding: "0px 5px", backgroundColor:  part["color"], opacity: 0.8 }}>
<div
  onClick={(e) => {
    console.log(e)
    let tempTopBottom = topBottom
    tempTopBottom[part.variable.split("_seg")[0] + "_orientation"] = e.target.value
    console.log(tempTopBottom)
    setTopBottom(tempTopBottom)
    setRadioChange(!radioChange)
  }}
>
<input 
  type="radio"
  checked={valueForRadio(part)=="top" ? "checked" : ""}
  name={part["variable"]} value={"top"} style={{ margin: "0px 5px" }}/>
  Top
</div>
{/* </input> */
}
{
  /* <div
  onClick={(e) => {
    console.log(e)
    let tempTopBottom = topBottom
    tempTopBottom[part.variable.split("_seg")[0] + "_orientation"] = e.target.value
    console.log(tempTopBottom)
    setTopBottom(tempTopBottom)
    setRadioChange(!radioChange)
  }}
> */
}
{
  /* <input 
  type="radio"
  checked={valueForRadio(part)=="bottom" ? "checked" : ""}
  name={part["variable"]} value={"bottom"} style={{ margin: "0px 5px" }}/>
  Bottom
  </div> */
}
{
  /* </input> */
}
// </div> */}
